import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import Categories from './category-list';


//categories: [
//    {name: "All You Can Eat"},
//    {name: "Kids eat free"},
//    {name: "Mexican"},
//  ]
class CategoryPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      };
  }


  render() {
	let categoriesToDisplay = this.props.categories ? this.props.categories : this.props.topCategories;
    return (
      <div>
          <Categories categories={categoriesToDisplay}/>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  return { 
	topCategories: state.categoryReducer.categories
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPanel);
