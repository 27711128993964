import React, { Component } from "react";
import { connect, dispatch } from "react-redux";


class MallViewAds extends Component
{
	
	constructor(props)
	{
		super(props);
		this.state = 
			{
				
			}
	}

	updateState = (newSelectedLocation='') => {
		console.log("TestLocationPanel:updateState:" + newSelectedLocation);
		this.setState(
			{
				selectedLocation: newSelectedLocation,
			}
		);
	}
	
	handleChange = (event) => {
		this.updateState(event.target.value);
	}
	
	displayLocationPanel = () => {
		return (
			<div><h1>Mall View</h1>
			</div>
		)
	}
		
	render() 
	{
		return(
			<div>
				{this.displayLocationPanel()}
				<br/>
				<div className="category-view-main">
				    <div>text				
					</div>
				    <div>text				
					</div>
				    <div>text				
					</div>
				</div>
			</div>	
		);
	}
	
}

function mapDispatchToProps(dispatch) {
    return {
    };
  }
    
export default connect(null, mapDispatchToProps)(MallViewAds);
