import React from 'react';
import { connect } from "react-redux";
import './breadcrumb.css';

class BreadcrumbPanel extends React.Component {
    constructor() {
        super();
    }

    selectIndex(value) {
        // this.props.changeview(value);
        alert(value);
      }
    
    render() {
        return (
            <div className="breadcrumb">
                <a href="javascript:void(0)" onClick={() => this.selectIndex('RESTAURANT_VIEW')}>Restaurant</a>
                ->
                <a href="javascript:void(0)" onClick={() => this.selectIndex('CHILD_EAT_FREE')}>Children Eat Free</a>
                ->
                <a href="javascript:void(0)" onClick={() => this.selectIndex('MEXICAN')}>Mexican</a>
            </div>  
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
    //   changeview: arg => dispatch(handleMainViewChange(arg))
    };
  }

  
  // Maps the reducer state to class props
  const mapStateToProps = state => {
    return { 
      searchResultsPayload: state.searchResultsReducer,
      globalReducerPayload: state.globalReducer  
    };
  };
    
  export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbPanel);
