import { LOCATION_SEARCH, LOCATION_GET_ADDRESS } from "../constants/action-types";
// import {useCallback} from 'react';
import {useDispatch, useStore} from 'react-redux';
import axios from 'axios';

const mockMode = true;
let apiUrl = 'https://maps.googleapis.com/maps/api';
let apiContext = '/geocode/json?';
//address=1926+Landridge+Drive,+Allen,+TX&key=AIzaSyAroUxEb8my2_DKhg0x9Ltul2zV1yeNkfU'
let apiContext1 = '/geocode/json?latlng=33.1180512,-96.7275947&key=AIzaSyAroUxEb8my2_DKhg0x9Ltul2zV1yeNkfU'
let instance = null;

const initialState = {
    address:{
        address1: '',
        city: '',
        state: '',
        zip: ''
    },
    geolocation: {
        lattitude: '',
        longitude: ''
    }
};

function createAxiosInstance() {
    if (instance == null){
        instance = axios.create({
            baseURL: apiUrl,
            timeout: 1000,
            headers: {
             'X-Custom-Header': 'foobar',
             'Access-Control-Allow-Origin': '/*',
             'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
             'Access-Control-Request-Headers': '*'
        },
            withCredentials: false
        
          });            
    }
}

// Different dispatch methods:
// 1. Import store and call store.dispatch
// 2. Pass in dispatch as an argument from the React call.

function searchAddressApi(value, responseFunction, errorFunction){
    console.log('SearchAddress: ' + JSON.stringify(value));
    let searchResponse = '';
    // let searchAddressQuery = apiContext + 'address=' + value.address1.trim() + '+' + 
    // value.city.trim() + '+' + value.state.trim() + '+' + value.zip.trim()
    // + '&key=AIzaSyAroUxEb8my2_DKhg0x9Ltul2zV1yeNkfU'
    // //address=1926+Landridge+Drive,+Allen,+TX&key=AIzaSyAroUxEb8my2_DKhg0x9Ltul2zV1yeNkfU'
    console.log('searchAddressAPI = searchQuery: ' + value);
      createAxiosInstance();
        instance.get(value)
        .then(function(response){
            console.log('GET:', response);
            responseFunction(response);
            // successFunction(response);
            // store.dispatch(handleApiResultsSuccess(response.data));
                })
        .catch(error => {
            // handle error
            errorFunction(error);
            console.log('ERROR:' + error);
                  })
        .finally(function () {
            // always executed
          });

}

const searchAddress = (dispatch, value) => {
    const responseFunction = (response) => {

        let address_components = response.data.results[0].address_components &&  Array.from(response.data.results[0].address_components).map((listItem) => {
            return {
                key: listItem.types[0],
                value: listItem.long_name   
            }
               })

        let city = '';       
        let state = '';       
        address_components.map((value) => {
            if (value.key.toUpperCase().trim() == 'LOCALITY'){
                city = value.value;
             }
             console.log(value);
            if (value.key.toUpperCase().trim() == 'ADMINISTRATIVE_AREA_LEVEL_1'){
               state = value.value;
            }
        });       
        console.log(state);

        let searchResponse = {
            type: LOCATION_GET_ADDRESS,
            payload: { 
                address: response.data.results[0].address_components[0].short_name,
                city: city,
                state: state,
                zip: '75013',
                latitude: response.data.results[0].geometry.location.lat,
                longitude: response.data.results[0].geometry.location.lng
        
    }};

            console.log('SEARCHADDRESS.responseFunction: ' + response);
            searchResponse.payload.jsonstring=JSON.stringify(response);
        dispatch(searchResponse);
    }
    const errorFunction = (error) => {
        let searchResponse = {
            type: LOCATION_GET_ADDRESS,
            payload: initialState
          };
            console.log('SEARCHADDRESS.errorFunction: ' + error);
        dispatch(searchResponse);
    }

    console.log('SearchAddress: ' + JSON.stringify(value));
    let searchResponse = '';
    let searchQuery = '';

    searchAddressApi(value, responseFunction, errorFunction);

}

function getLocationFromAddress(dispatch, address, city, state, zip){
    let searchQuery = (address ? 'address=' + address.trim() : '')
    + (city ? '+' + city.trim() : '')
    + (state ? '+' + state.trim() : '')
    + (zip ? '+' + zip.trim() : '')
    + '&key=AIzaSyAroUxEb8my2_DKhg0x9Ltul2zV1yeNkfU';

}

function getAddressFromLocation(dispatch, latitude, longitude){
    let searchQuery = '/geocode/json?latlng=' + latitude + ',' + longitude + '&key=AIzaSyAroUxEb8my2_DKhg0x9Ltul2zV1yeNkfU';
    searchAddress(dispatch, searchQuery);
}

function getCurrentLocation(dispatch){
         navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            console.log(position);
          let currentLocationResponse = {
            type: LOCATION_SEARCH,
            payload: {
                locationFound: true,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              }
            };
dispatch(currentLocationResponse);
            //getAddressFromLocation(dispatch, position.coords.latitude, position.coords.longitude);
          },
          function(error) {
              console.error("Error Code = " + error.code + " - " + error.message);
            }
          );      
}

export {getLocationFromAddress, getAddressFromLocation, getCurrentLocation} 
