import { APIRESULTS, GET_VENDOR_DETAILS_AND_DISPLAY } from "../constants/action-types";

const initialState = {
  vendorid: 0,
  companyName: "Vendor ABC",
  longDescription: "<h1>Vendor ABC</h1>",
  categories: [
    {name: "All You Can Eat"},
    {name: "Kids eat free"},
    {name: "Mexican"},
  ],
  ads: [
    {title: "All You Can Eat"},
    {title: "Kids eat free"},
    {title: "Mexican"},  
  ],
  totalAds: 3
};

function vendorReducer(state = initialState, action) {
  console.log('LOG-VendorReducer', action);
  console.log('LOG-VendorReducer', action.payload);
   
 switch (action.type) {
    case (GET_VENDOR_DETAILS_AND_DISPLAY):
		copyAds(action.payload.ads);
		console.log(JSON.stringify(action));
		let response = {
			  vendorid: action.payload.vendorid,
			  companyName: action.payload.companyName,
			  longDescription: action.payload.longDescription,
			  website: action.payload.website,
			  categories: [
			    {name: "All You Can Eat"},
			    {name: "Kids eat free"},
			    {name: "Mexican"},
			  ],
			  categories: copyCategories(action.payload.categories),
			  ads: copyAds(action.payload.ads),
			  totalAds: 3
			};
			return response;

      break;
	}
	
//   if (action.type === APIRESULTS) {
//     return action.payload.data;
//   }
  return state;
}

function copyAds(ads)
{
	let adsList = [];
	if (ads)
	{
		ads.forEach(function (ad){ adsList.push({title: ad.title})});	
	}
	return adsList;
}

function copyCategories(categories)
{
	let categoryList = [];
	if (categories)
	{
		categories.forEach(function (category){ categoryList.push({name: category.name})});	
	}
	return categoryList;
}

export default vendorReducer;