import React, { Component} from "react";

import ReactBasics from "./react-basics/react-basic";
import ReactInputs from "./inputs/react-inputs";
import ReactHooks from "./hooks/react-hooks";
import ReactVersions from "./versions";
import JavascriptCollections from "./collections/react-collections";

class ReactSamples extends Component {
    constructor(props) {
        super(props);
		this.state = {
			count: 0
		}

      }   

	renderComponent() 
	{
		switch (this.state.count)
		{
			case 0: return <ReactBasics/>
			case 1: return <ReactInputs/>
			case 2: return <ReactHooks/>
			case 3: return <ReactVersions/>
			case 4: return <JavascriptCollections/>
			default:
				return <ReactInputs/>	
		}
	}

      render() {	
        return (
            <div>
				  <button onClick={() => this.setState({ count: 0})}>React Basics</button>&nbsp;
				  <button onClick={() => this.setState({ count: 1})}>React Inputs</button>&nbsp;
				  <button onClick={() => this.setState({ count: 2})}>React Hooks</button>&nbsp;
				  <button onClick={() => this.setState({ count: 3})}>Component Versions</button>&nbsp;
				  <button onClick={() => this.setState({ count: 4})}>Javascript Collections</button>

				<div>
					{this.renderComponent()}
				</div>
            </div>
        );
      }
    }
    
export default ReactSamples;
