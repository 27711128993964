import React, { Component } from "react";
import Button from './button';
import MyCheckbox from './my-checkbox';
import MyLinkList from './my-link-list-component';

class SelectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectionMode: false,
        selectedLocation: '',
        options: 
            [
                {id: 1, value: 'option 1', selected:false},
                {id: 2, value: 'option 2', selected:true},
                {id: 3, value: 'option 3', selected:false},
                {id: 4, value: 'option 4', selected:false}
            ]
      };
      this.linkSelected = this.linkSelected.bind(this);
  }

  linkSelected(linkId) {
      alert('link Selected: ' + linkId);
  }

  createLinks()
  {
      return(
        <div>
        {Array.from(this.state.options).map((option) => {
            return <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(option.id)}>{option.value}</a>
     })}
         </div>
        );     
  }

  createRadioButtons()
  {

      return(
        <div>
        {Array.from(this.state.options).map((option) => {
            if (option.selected)
            {
                return <div><label>
                <input
                name="group"
                type="radio"
                value={option.id}
                defaultChecked={true}
                onChange={() => this.optionSelected()}
                />
                {option.value}</label></div>
    
            } else
            {
                return <div><label>
                <input
                name="group"
                type="radio"
                value={option.id}
                defaultChecked={false}
                onChange={() => this.optionSelected()}
                />
                {option.value}</label></div>
                }
     })}
        </div>
        );
  }

  createDropDown()
  {
      return(
            <select value='2'>
                {Array.from(this.state.options).map((option) => {
      return <option value={option.id}>{option.value}</option>   
     })}
            </select>
        );
  }

  createCheckbox()
  {
      return(
        <div onChange={() => this.optionSelected()}>
        {Array.from(this.state.options).map((option) => {
            if (option.selected)
            {
                return <div><label>
                <input
                name="group"
                type="checkbox"
                defaultChecked={true}
                onChange={() => this.optionSelected()} />
                {option.value}</label></div>
    
            } else
            {
                return <div><label>
                <input
                name="group"
                type="checkbox"
                defaultChecked={false}
                onChange={() => this.optionSelected()} />
                {option.value}</label></div>
                }
     })}
        </div>
        );
  }

  render() {
    return (
        <div>
            {this.createDropDown()} <br/>
            {this.createRadioButtons()} <br/>
            {this.createLinks()}<br/>
            {this.createCheckbox()}<br/>
            <MyCheckbox options={[
                    {id: 1, value: 'option 10', selected:false},
                    {id: 2, value: 'option 20', selected:true},
                    {id: 3, value: 'option 30', selected:false},
                    {id: 4, value: 'option 40', selected:false}
                ]}/>
                <br/>
            <MyLinkList linkSelected={this.linkSelected} options={[
                    {id: 1, value: 'option 10', selected:false},
                    {id: 2, value: 'option 20', selected:true},
                    {id: 3, value: 'option 30', selected:false},
                    {id: 4, value: 'option 40', selected:false}
                ]}/>
        </div>
    );
  }
}

export default SelectionComponent;
