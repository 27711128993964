import React from 'react';
import { connect } from "react-redux";
import { handleApiResultsSuccess } from "../actions/index";
import store from "../store";
import {get} from './http-calls';

export function search() {
    console.log('api-calls.search 1:')
    let successFunction = (response) => {
        store.dispatch(handleApiResultsSuccess(response.data));
    }
    let errorFunction = (error) => {
        console.log('Search Error in http call: ' + error);
    }
    get('test', successFunction, errorFunction);
}