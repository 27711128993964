import React, { useState } from "react";

function displayIntro()
{
		return (
			<div>
				<h1>CSS Border</h1>
				<a href="https://www.w3schools.com/css/default.asp">W3 School HTML Reference</a>
				<br/>
				CSS Properties: <a href="https://www.w3schools.com/cssref/default.asp">https://www.w3schools.com/cssref/default.asp</a>
				<br/>
				
			</div>	
		);
}

function displayBorderProperties()
{
		return (
			<div>
				<br/>
				<div style={{border: "2px solid black"}}>
					Border Properties:
					<lu>
						<li>border: 2px solid black</li>
						<li>border-color: coral | #0xFFFFFF</li>
						<li>border-image: url("name.gif")</li>
						<li>border-radius: length (1px) | topLength(1px) sideLength(1px)</li>
						<li>border-spacing length (1px)|initial|inherit</li>
						<li>border-style: none | hidden | solid | dotted | dashed | double | groove | ridge | inset | outset</li>
						<li>border-width: medium|thin|thick|length (1px)|initial|inherit</li>
						<br/>
						<li>border-[top-bottom-right-left]</li>
						<li>border-[top-bottom-right-left]-color: coral | #0xFFFFFF</li>
						<li>border-[top-bottom]-[right-left]-radius: length (1px)</li>
						<li>border-[top-bottom-right-left]-style: none | hidden | solid | dotted | dashed | double | groove | ridge | inset | outset</li>
						<li>border-[top-bottom-right-left]-width: medium|thin|thick|length (1px)|initial|inherit</li>						

					</lu>
						
				</div>
			</div>	
		);
}

function CssBorder() {
  return (
    <div>
	  	{displayIntro()}<br/>
	  	{displayBorderProperties()}
    </div>
  );
}

export default CssBorder;
