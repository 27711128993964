import React, { Component } from "react";

// Props:
//options={[
//                    {id: 1, value: 'option 10', selected:false},
//                    {id: 2, value: 'option 20', selected:true},
//                    {id: 3, value: 'option 30', selected:false},
//                    {id: 4, value: 'option 40', selected:false}
//                ]}
class MyCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
			options: props.options
          };
          this.optionSelected = this.optionSelected.bind(this);
      }
 
	setCheckboxForId(id, checkValue)
	{
		let options = this.props.options;
		{Array.from(options).map((option) => {
                if (option.id == id )
                {
					option.selected = checkValue
                }
         })}

		this.setState({options:options})
	}
	   
      optionSelected(event) {
        console.log(event.target);
//        alert('optionSelectedForEventOnly: ' + event.target.checked + ' -type: ' + event.type + ' - name: ' + event.target.name + ' - id: ' + event.target.id);
		this.setCheckboxForId(event.target.id, event.target.checked);
    }
    
      createCheckbox()
      {
          return(
            <div onChange={this.optionSelected}>

            {Array.from(this.props.options).map((option) => {
				if (option.selected)
                {
                    return <div><label>
                    <input
                    name={option.id}
					id={option.id}
                    type="checkbox"
                    checked={true}
					onChange={this.optionSelected}/>
                    {option.value}</label></div>
        
                } else
                {
                    return <div><label>
                    <input
                    name={option.id}
					id={option.id}
                    type="checkbox"
                    checked={false}
					onChange={e => {this.optionSelected(e, option.id)}}/>
                    {option.value}</label></div>
                    }
         })}
			{JSON.stringify(this.state)} <br/><br/>
			{JSON.stringify(this.props.options)} <br/>
            </div>
            );
      }
    
      render() {
        return (
            <div>
                {this.createCheckbox()}<br/>
            </div>
        );
      }
    }
    
export default MyCheckbox;
