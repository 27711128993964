import React, { useState } from "react";
import UseStateHook from './usestate';

function ReactHooks() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <div>
		<h1>Introduction to React Hooks</h1>
		<a href='https://reactjs.org/docs/hooks-intro.html'> React.js Hooks Reference</a>
		<br/>
		Excerpt from the react hooks FAQ (<a href="https://reactjs.org/docs/hooks-faq.html#what-can-i-do-with-hooks-that-i-couldnt-with-classes">React Hooks FAQ</a>): <br/>	
		Should I use Hooks, classes, or a mix of both? <br/>
When you’re ready, we’d encourage you to start trying Hooks in new components you write. Make sure everyone on your team is on board with using them and familiar with this documentation. We don’t recommend rewriting your existing classes to Hooks unless you planned to rewrite them anyway (e.g. to fix bugs).
You can’t use Hooks inside a class component, but you can definitely mix classes and function components with Hooks in a single tree. Whether a component is a class or a function that uses Hooks is an implementation detail of that component. In the longer term, we expect Hooks to be the primary way people write React components.

		<hr/>
		<UseStateHook/>
    </div>
  );
}

export default ReactHooks;
