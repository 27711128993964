import React from 'react';
import { connect } from "react-redux";
import Board from './board';
import './index.css';
import {searchForBannerAds} from "../../js/actions/search-action";

class Game extends React.Component {

  buttonPressed = () => {
    alert('test');
    console.log('Pressing a button');
    this.props.search('TEST');
  }

    render() {
      return (
        <div className="game">
          <div className="game-board">
            <Board />
            <div>
            <br/>
            <button value='TEST' style={{width: 50, height: 25 }} onClick={() => this.buttonPressed()}>TEST</button>
          </div>
          </div>
          <div className="game-info">
            <div>{/* status */}</div>
            <ol>{/* TODO */}</ol>
          </div>
        </div>
      );
    }
  }

    
function mapDispatchToProps(dispatch) {
  return {
    search: arg => dispatch(searchForBannerAds(arg))
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('LOG-Banner.jsx', state);
  return { 
    
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Game);
