import React from 'react';
import { connect } from "react-redux";
import {VIEW_SEARCH, VIEW_VENDOR_DETAILS} from '../../js/constants/global';
import {searchForBannerAds} from "../../js/actions/search-action";
import {handleMainViewChange} from "../../js/actions/index";
import {loadVendorFromSearchListItem} from "../../js/actions/vendor-action";
import  AdvertisementPanel from "../panels/advertisement-panel/advertisement-panel";
import CategoryPanel from "../panels/category-panel/category-panel";
import './searchresults.css';


const LongDisplayType = 0;
const ShortDisplayType = 1;
class SearchResults extends React.Component {
	constructor(props){
		super(props);

        this.state = {
			displayType: LongDisplayType
          };

	}

  buttonPressed = () => {
    console.log('SearchResults-buttonPressed 1:Pressing a button');
    this.props.search('TEST');    
  }

  detailButtonPressed = () => {
    console.log('WorkArea-buttonPressed 1:Pressing a button');
    this.props.changeview(VIEW_VENDOR_DETAILS);    
  }

  displayTypeButtonPressed = (value) => {
	this.setState({displayType:value})	
  }

  renderShortDisplay(listItem)
{
   return(<div><div className="searchResultsItem">
                <div>{listItem.companyName}
                Phone: {listItem.phone}
                Website: {listItem.website}<br/>
			    </div>
				<div><CategoryPanel categories={listItem.categories}/></div>
</div>
<div className="searchResultsItem">
                <div className="adPanel">
                <div dangerouslySetInnerHTML={ { __html: listItem.longDescription } }></div>
                <AdvertisementPanel ads={listItem.ads} totalads={listItem.totalAds}/>
                </div>
</div>
                <hr/>

            </div>)
}
  renderLongDisplay(listItem)
{
   return(<div><div className="searchResultsItem">
                <div>
<a href="j" onClick={() => this.props.loadItem(listItem)}>{listItem.companyName}</a>
                Phone: {listItem.phone}<br/>
                Website: {listItem.website}<br/>
			    </div>
				<div>
                Address: <br/>
                City, State Zip
                </div>
				<div><CategoryPanel categories={listItem.categories}/></div>
</div>
<div className="searchResultsItem">
                <div className="adPanel">
                <div dangerouslySetInnerHTML={ { __html: listItem.longDescription } }></div>
                <AdvertisementPanel ads={listItem.ads} totalads={listItem.totalAds}/>
                </div>
</div>
                <hr/>

            </div>)
}

  render() {
    const vendorDisplayName = this.props.searchResultsPayload != null ? this.props.searchResultsPayload.companyName : "";
    const vendorCustomCode = this.props.searchResultsPayload != null ? this.props.searchResultsPayload.longDescription : "";
    const searchList = this.props.searchResultsPayload != null ? this.props.searchResultsPayload : {};
      return (
        <div>
  			<div>
		   <label><input type="radio" value="short display" checked={this.state.displayType == 1} onClick={() => this.displayTypeButtonPressed(ShortDisplayType)}/>Short</label>
		   <label><input type="radio" value="long display" checked={this.state.displayType == 0} onClick={() => this.displayTypeButtonPressed(LongDisplayType)}/>Long</label>
		<br/>
          Search Results <br/>

		</div>
{ this.props.searchResultsPayload &&  Array.from(this.props.searchResultsPayload) .map((listItem) => {
     return (this.state.displayType == 0 ? this.renderLongDisplay(listItem) : this.renderShortDisplay(listItem))
})}
        </div>
      );
  } 

  renderOld() {
    const vendorDisplayName = this.props.searchResultsPayload != null ? this.props.searchResultsPayload.companyName : "";
    const vendorCustomCode = this.props.searchResultsPayload != null ? this.props.searchResultsPayload.longDescription : "";
    const searchList = this.props.searchResultsPayload != null ? this.props.searchResultsPayload : {};
      return (
        <div>
          Search Results
           
              {/* <button value='SEARCH' style={{width: 50, height: 25 }} onClick={() => this.buttonPressed()}>Search</button> */}
              {/* <br/><br/> */}
              {vendorDisplayName}
              <div dangerouslySetInnerHTML={ { __html: vendorCustomCode } }></div>
            
              <tbody>

{ this.props.searchResultsPayload &&  Array.from(this.props.searchResultsPayload) .map((listItem) => {
     return (<tr style ={{border: 1}}>
                <td>{listItem.companyName}</td>
                <td><div dangerouslySetInnerHTML={ { __html: listItem.longDescription } }></div></td>
                <td>
                {/* <button value='SEARCH' style={{width: 50, height: 25 }} onClick={() => this.detailButtonPressed()}>More&nbsp;Details</button> */}

                </td>
            </tr>)
})}
</tbody>
        </div>
      );
    }
  } 
    
  function mapDispatchToProps(dispatch) {
    return {
      search: arg => dispatch(searchForBannerAds(arg)),
      changeview: arg => handleMainViewChange(arg),
 	  loadItem: arg => loadVendorFromSearchListItem(arg)
    };
  }
  
  // Maps the reducer state to class props
  const mapStateToProps = state => {
    console.log('SearchResults-mapStateToProps 1:', state);
    return { 
      searchResultsPayload: state.searchResultsReducer  
    };
  };
  
 
  export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
    