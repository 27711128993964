import React from 'react';
import { connect } from "react-redux";
import { LOCATION_UPDATE } from "../constants/action-types";
import { handleApiResultsSuccess } from "../actions/index";
import store from "../store";
import {get} from './http-calls';

export function getLocation(locationId, actionType=LOCATION_UPDATE) {
    console.log('location-api.getLocation 1:' + locationId);
    let successFunction = (response) => {
        console.log("GetLocation:" + response.data);
       store.dispatch({type: actionType, payload : response.data});
    }
    let errorFunction = (error) => {
        console.log('Search Error in http call: ' + error);
    }
    get("/location/" + locationId + "?includeParent=true", successFunction, errorFunction);
}

export function getLocationGroup(parentLocationId) {
    console.log('location-api.getLocationGroup 1:')
    let successFunction = (response) => {
        console.log("GetLocationGroup:" + response.data);
       store.dispatch({type: 'LOCATIONGROUP_UPDATE', payload : response.data});
    }
    let errorFunction = (error) => {
        console.log('Search Error in http call: ' + error);
    }
    get("/locationgroup/" + parentLocationId, successFunction, errorFunction);
}

export function getLocationGroups(locationId1, locationId2, locationId3, locationId4) {
    console.log('location-api.getLocationGroups 1:' + locationId1 + ':' + locationId2 + ":" + locationId3 + ":" + locationId4);
    let successFunction = (response) => {
        console.log("GetLocationGroups:" + response.data);
       store.dispatch({type: 'LOCATIONGROUP_UPDATE', payload : response.data});
       if(locationId2)
          getLocationGroup(locationId2);
    }
    let errorFunction = (error) => {
        console.log('Search Error in http call: ' + error);
    }
    get("/locationgroup/" + locationId1, successFunction, errorFunction);
}