import { CATEGORY_SELECTION_PANEL_CATEGORIES_UPDATE} from "../constants/action-types";

const initialState = {
	parentCategoryId: 1,
	parentCategoryName: 'TEST',
    childCategoryOptions:
        [{"id":41, "uniqueId": "US:TEXAS", "name": "Texas"},
        {"id":100, "uniqueId": "US:TEXAS:ALLEN", "name": "Allen"}
		]
};

function categorySelectionReducer(state = initialState, action) {
  console.log('LocationReducer', action);
   
  if (action.type === CATEGORY_SELECTION_PANEL_CATEGORIES_UPDATE) {
	let payload = action.payload;
	let newState = {
		parentCategoryId: payload.categoryId.id,
		parentCategoryName: payload.categoryId.name,
		childCategoryOptions: getChildCategories(payload.childCategories)
		
	}
	return newState;
  }

return state;
}

function getChildCategories(childCategoriesToConvert)
{
	if (childCategoriesToConvert && childCategoriesToConvert.length > 0)
	{
		let result = Array.from(childCategoriesToConvert).map((childCategory) => ({id: childCategory.id, name: childCategory.name}));
		return result;
	}	

}
export default categorySelectionReducer;