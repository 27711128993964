import { LOCATION_SELECTION_PANEL_INIT_LOCATIONS } from "../constants/action-types";
import { getLocation, getLocationGroup } from "../api-integration/location-api";


const initLocation = (dispatch, value) => {
        let searchResponse = {
            type: LOCATION_SELECTION_PANEL_INIT_LOCATIONS,
            payload: { 
    "id": 41,
    "name": "Texas",
    "locationId": "US:TEXAS",
    "parentLocations": [
        {
            "id": 1,
            "name": "US",
            "locationId": "US",
            "parentLocations": []
        }
    ]
        
    }};
	getLocation(value, LOCATION_SELECTION_PANEL_INIT_LOCATIONS);
//	getLocationGroup(value);
}


export {initLocation} 
