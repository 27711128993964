// import React from 'react';
// import { connect } from "react-redux";
// import { handleApiResultsSuccess } from "../actions/index";
// import store from "../store";
import axios from 'axios';
import {mockGet} from "./mock-http-calls";

const mockMode = false;
let apiUrl = 'http://localhost:8080';
let apiContext = '/geocode/json?';

let instance = null;

//   JSON.parse(stringValue, function(k, v) {
//     if (v && typeof v === 'object' && !Array.isArray(v)) {
//       return Object.assign(Object.create(null), v);
//     }
//     return v;
//  });
function createAxiosInstance() {
    if (instance == null){
        instance = axios.create({
            baseURL: apiUrl,
            timeout: 5000,
            headers: {'X-Custom-Header': 'foobar',
            'Access-Control-Allow-Origin': '/*',
            'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT'
            // 'Access-Control-Request-Headers': '*'
        },
            withCredentials: false
        
          });            
    }
}

export function get(value, successFunction, errorFunction){
    console.log("1: GET");
    if (mockMode) {
        mockGet(value, successFunction, errorFunction);
    } else {
        createAxiosInstance();
        instance.get(value)
        .then(function(response){
            console.log('GET:', response);
            successFunction(response);
            return response;
        })
        .catch(function (error) {
            // handle error
            console.log("get error:" + error);
            errorFunction(error);
            console.log(error);
            return 'error';
          })
        .finally(function () {
            // always executed
          });    
    }
}

export function api_get(value) {
   // axios.get('http://www.espn.com');
}
