export const SELECT_SQUARE= "SELECT_SQUARE";
export const CHANGE_PLAYER= "CHANGE_PLAYER";
export const APIRESULTS = "API_RESULTS";
export const CHANGE_VIEW = "CHANGE_VIEW";

export const SEARCH_BANNER = "SEARCH_BANNER";
export const LOCATION_SEARCH = "LOCATION_SEARCH";
export const LOCATION_GET_ADDRESS = "LOCATION_GET_ADDRESS";
export const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";
export const GET_VENDOR_DETAILS_AND_DISPLAY = "GET_VENDOR_DETAILS_AND_DISPLAY";

export const CATEGORY_RETRIEVAL_UPDATE = "CATEGORY_RETRIEVAL_UPDATE";
export const LOCATION_SELECTION_PANEL_INIT_LOCATIONS = "LOCATION_SELECTION_PANEL_INIT_LOCATIONS";
export const CATEGORY_SELECTION_PANEL_CATEGORIES_UPDATE = "CATEGORY_SEL_UPDATE";

export const SEARCH_CRITERIA_CATEGORY_REMOVEALL = "SEARCH_CRITERIA_CATEGORY_REMOVEALL";
export const SEARCH_CRITERIA_CATEGORY_ADD = "SEARCH_CRITERIA_CATEGORY_ADD";
export const SEARCH_CRITERIA_CATEGORY_DELETE = "SEARCH_CRITERIA_CATEGORY_DELETE";

export const LOCATION_UPDATE="LOCATION_UPDATE";

export const GLOBAL_LOCATION_INIT="GLOBAL_LOCATION_INIT";
export const GLOBAL_COLORSCHEME_INIT="GLOBAL_COLORSCHEME_INIT";
