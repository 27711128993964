import React from 'react';
import { connect, dispatch } from "react-redux";
import Header from './header/header';
import Navigation from './nav/nav'
import TicTacToe from './tictactoe/index';
import SearchResults from './searchresults/searchresults';
import WorkArea from './workarea/workarea';
import Details from './business/business-details';
import CategoryPanel from './panels/category-panel/category-panel';
import Location from './panels/location';
import LocationPanel from './panels/location-panel/location-panel';
import UIPanel from './panels/ui-components/ui-panel';
import '../css/reset.css';
import '../css/style.css';
import {VIEW_SEARCH, VIEW_VENDOR_DETAILS, VIEW_LOCATION_SELECTION} from '../js/constants/global';
import locationSummary from './panels/location-panel/location-summary';
import { getLocationGroups, getLocation } from "../js/api-integration/location-api";
import {setColorScheme} from "../js/actions/global-action";
import MallView from "./main/mall-view/mall-view-main";
import CityView from "./main/city-view/city-view-main";
import ChamberOfCommerceView from "./main/chamber-of-commerce-view/coc-view-main";
import ShoppingCenterView from "./main/shopping-center-view/shoppingcenter-view-main";
import MyButton from "./utils/my-button";

class BaseSite extends React.Component {
    constructor() {
        super();
        this.state = {
          count: 1,
        };
      }
    componentDidMount()
    {
      // this.props.getLocationGroups(41, 100);
      this.props.getLocation("US:TEXAS:ALLEN");
    }

      updateCount(newCount = 1) {
        this.setState((prevState, props) => {
          return { count: newCount }
        });
      }
    
    mySwitchFunction = (param) => {
      // return <TicTacToe/>
     return <WorkArea/>        
 
     }

	renderNavigation()
	{
		switch (this.props.global.view) {
			case VIEW_LOCATION_SELECTION:
			   return <div></div>;
			default:
			   return <header><Navigation/> <Header/></header>;
		}
	}

	renderCategoryPanel()
	{
		switch (this.props.global.view) {
			case VIEW_LOCATION_SELECTION:
			   return <div></div>;
			default:
			   return <nav><CategoryPanel/></nav>;
		}
	}

	renderMainPanel()
	{
		switch (this.props.global.view) {
//			case VIEW_LOCATION_SELECTION:
//			   return <LocationPanel/>;
			default:
			   return this.mySwitchFunction(this.state.count);
		}
	}
	
	renderTypeOfView()
	{
		switch(this.state.count)
		{
			case 1: return <MallView/>
			case 2: return <CityView/>
			case 3: return <ChamberOfCommerceView/>
			case 4: return <ShoppingCenterView/>
		}
	}
	renderViewSelection()
	{
		return (<div>
		<button onClick={() => this.updateCount(1)}>Mall View </button>&nbsp;
		<button onClick={() => this.updateCount(2)}>City View </button>&nbsp;
		<button onClick={() => this.updateCount(3)}>Chamber of Commerce View </button>&nbsp;
		<button onClick={() => this.updateCount(4)}>Shopping Center View </button>
		</div>);
		
		
	}

	renderColorSchemeSelector()
	{
		return (<div>
		<button onClick={() => this.props.setColorScheme("blue")}>Blue Color Scheme </button>&nbsp;
		<button onClick={() => this.props.setColorScheme("red")}>Red Color Scheme </button>&nbsp;
		<button onClick={() => this.props.setColorScheme("green")}>Green Color Scheme </button>&nbsp;
		
		</div>);
		
		
	}
	
	renderColorScheme()
	{
		console.log("BASESITE - renderColorScheme: " + JSON.stringify(this.props.global));
		console.log("BASESITE - renderColorScheme: " + this.props.global.colorScheme);
		let currentColorScheme = "header-" + this.props.global.colorScheme;
		return (currentColorScheme);
	}
	
	renderHeader()
	{
		return (			   
			<div classname={this.renderColorScheme()}>
			<h1>THIS IS A HEADER</h1>
				<button className={this.renderColorScheme() + "-button"}>Favorites</button>
				<button className={this.renderColorScheme() + "-button"}>Restaurants</button>
			</div>
			);
		
	}

    render() {
      return (
        <div className="container">
		  {this.renderColorSchemeSelector()}
			{this.renderViewSelection()}
			<header className={this.renderColorScheme()}>
				{this.renderHeader()}
			</header>
          <main>
			{this.renderTypeOfView()}
            {this.renderMainPanel()}           

          </main>
          <footer>
FOOTER
          </footer>        
        </div>
      );
    }
  

    renderOld() {
      return (
        <div ClassName="container">
			{this.renderViewSelection()}
		  {this.renderColorSchemeSelector()}
          {this.renderNavigation()}
			<header>
			Header
			</header>
          {this.renderCategoryPanel()}
          <main>
            {this.renderMainPanel()}           
          </main>
          {/* <aside><CategoryPanel/></aside> */}
          <footer>
            <UIPanel></UIPanel>
          </footer>        
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
        getLocationGroups: (locationId1, locationId2) =>  getLocationGroups(locationId1, locationId2),
        getLocation: (locationId) =>  getLocation(locationId),
		setColorScheme: (newColorScheme) => setColorScheme(newColorScheme)
    };
  }

  const mapStateToProps = state => {
    return { 
      global: state.globalReducer  
    };
  };
    
  export default connect(mapStateToProps, mapDispatchToProps)(BaseSite);
  