import React, { Component } from "react";
import { render } from "react-dom";
import { connect, dispatch } from "react-redux";
import { initLocation } from "../../../js/actions/location-selection-action";
import { getLocationGroup } from "../../../js/api-integration/location-api";
import GeoLocation from "./geo-location";
import LocationGroups from "./location-groups";
import LocationSelection from "./location-selection";
import LocationSummary from "./location-summary";
import MyLinkList from '../ui-components/my-link-list-component';

class LocationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectionMode: false,
        selectedLocation: ''
      };
      this.selectLocation = this.selectLocation.bind(this);
  }

  componentDidMount() {
  }

  toggleOpen() {
      this.setState({selectionMode:true})
  }

  toggleClose() {
    this.setState({selectionMode:false})
  }

  renderComponents() {
	if (this.props.initialLocation != this.state.selectedLocation)
	{
		this.setState({selectedLocation:this.props.initialLocation});
		this.props.initLocation(this.props.initialLocation);
	}
	console.log("LocationPanel:renderComponents: " + this.props.initialLocation);
      // if (this.state.selectionMode)
      // {
      //     return (
//          return <LocationGroups selectedLocation={this.state.selectedLocation}/>  
      //     );
      // }
      // return (<div></div>)

	return (
		<div>
          <LocationSelection initialLocation={this.props.initialLocation}/>
		</div>
		)
  }

  selectLocation(location) {
    //alert('Location selected:' + location);
    // this.setState({selectionMode:false, selectedLocation: location})
    this.props.getLocationGroup(location);
  }

  render() {
    return (
      <div>      
        <LocationSummary selectedLocations={this.props.location.selectedLocations} selectFunction={this.selectLocation}/>        
          <a href="javascript:void(0)" onClick={() => this.toggleOpen()} Style="font-size:8px">Change Location</a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="javascript:void(0)" onClick={() => this.toggleClose()} Style="font-size:8px">Close</a>
          {this.renderComponents()}
          <hr/>
 
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
	initLocation: (locationId) => initLocation(dispatch, locationId),
    getLocationGroup: (parentLocationId) =>  getLocationGroup(parentLocationId)
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('Location-mapStateToProps 1:', state);
  return { 
    location: state.locationReducer  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationPanel);
