import React from 'react';
import { connect, dispatch } from "react-redux";
import TestLocationPanel from './site/panels/location-panel/test-location-panel';
import TestLocationCache from './testLocationCache';
import TestLocationPanels from './site/locationPanels/test-location-panels';
import TestCategoryPanel from './testCategoryPanel';
import TestSearchCriteria from './testSeachCriteria';
import TestSearchPanel from './testSearchPanel';
import TestSupplier from './site/supplier/test-supplier';
import ReactSamples from './reactsamples/react-samples';
import HtmlHelp from './html-help/html-intro';
import CssHelp from './css-help/css-help';
import ReducerDump from './reducer-dump';
import '../css/reset.css';
import '../css/style.css';
import './test.css';

  class TestSite extends React.Component {
    constructor() {
        super();
        this.state = {
          count: 0,
        };
      }

    componentDidMount()
    {
    }

	updateState = (newCount = 0) => {
		this.setState(
			{
				count: newCount
			}
		);
	}
	
    updateCount(newCount = 0) {
		this.updateState(newCount);
    }

	getMainPanel = (param) => {
      switch (this.state.count)
	{
		case 0: return <TestLocationPanels/>
		case 1:	return <TestCategoryPanel/>;
		case 6: return <TestSupplier/>
		case 2:	return <ReactSamples/>
		case 3: return <HtmlHelp/>
		case 5: return <CssHelp/>
		default:
			return <TestLocationPanel/>
	
	}
     return <div></div>        
 
     }

	getSecondaryPanel = (param) => {
      switch (this.state.count)
	{
		case 0:
		case 1:
		case 4:
				return (<div><TestSearchCriteria/> 
				<br/>
				<hr/>
				<ReducerDump/>
				</div>)
		case 2:
				return (<TestSearchPanel/>) 
		default:
     		return <div></div>        
	
	}
     return <div></div>        
 
     }

    render() {
		console.log('-------------------------------------------');
      return (
        <div ClassName="container">
           <div ClassName="main">
				<div className="testbody">
				<a href="javascript:void(0)" onClick={() => this.updateCount(0)}>Test Location Panels</a><br/>
				<a href="javascript:void(0)" onClick={() => this.updateCount(6)}>Test Supplier Panels</a><br/>
				<a href="javascript:void(0)" onClick={() => this.updateCount(1)}>Category Panel Test</a><br/>
				<a href="javascript:void(0)" onClick={() => this.updateCount(2)}>React Samples</a><br/>
				<a href="javascript:void(0)" onClick={() => this.updateCount(3)}>Html Help</a><br/>
				<a href="javascript:void(0)" onClick={() => this.updateCount(5)}>Css Help</a><br/>
				<a href="javascript:void(0)" onClick={() => this.updateCount(0)}>Other</a>
				</div> 
				<br/>
				<br/>
				<hr/>
	            {this.getMainPanel()}<br/>
	            {this.getSecondaryPanel()}<br/>
            </div>
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
    };
  }
    
  export default connect(null, mapDispatchToProps)(TestSite);
  