import React, { Component } from "react";

class MyLinkList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          };
          this.optionSelected = this.optionSelected.bind(this);
      }
    
      optionSelected(selectedId) {
        console.log(selectedId);
        alert('optionSelected: ' + selectedId);
      }   
    
      createLinks()
      {
          return(
            <div>
            {Array.from(this.props.options).map((option) => {
                return <div><a href="javascript:void(0)" onClick={() => this.props.linkSelected(option.id)}>{option.value}</a></div>
         })}
             </div>
            );            
      }
        
      render() {
        return (
            <div>
                {this.createLinks()}<br/>
            </div>
        );
      }
    }
    
export default MyLinkList;
