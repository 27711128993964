import { getCategories} from "../api-integration/category-api";
import {CATEGORY_SELECTION_PANEL_CATEGORIES_UPDATE} from "../constants/action-types";


const getCategoriesByParent = (value) => {
	getCategories(value, CATEGORY_SELECTION_PANEL_CATEGORIES_UPDATE);
}


export {getCategoriesByParent} 
