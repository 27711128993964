import React from "react";

function displayRedux()
{
	return (
		<div>
			<h1>Redux Section </h1>
			<a href="https://redux.js.org">Redux Main site (redux.js.org)</a>
			<br/>
			<a href="https://github.com/reduxjs/redux">Redux GitHub Home Page</a>
			<br/>
			<a href="https://github.com/reduxjs/redux/releases"> Redux Releases Page</a>
			<br/>
			Latest release: 4.0.5 (As of April 15, 2021)
			<br/>

		</div>
	)
}

function displayReactRedux()
{
	return (
	   <div>
			<h1>React Redux Section </h1>
			<a href="https://react-redux.js.org">React Redux Main Site (https://react-redux.js.org)</a>
			<br/>
			<a href="https://react-redux.js.org/introduction/getting-started"> React-Redux Start Page</a>
			<br/>
			<a href="https://github.com/reduxjs/react-redux/releases/">React-Redux Github Releases Page (Latest Release)</a>
			<br/>
			<br/>
			Latest release: 7.2.3 (As of April 15, 2021)
			<br/>
		</div>	
	);
}

function displayReactScripts()
{
	return (
	   <div>
			<h1>React Scripts Section </h1>
			<a href="https://www.npmjs.com/package/react-scripts"> React Scripts Package Information</a>
			<br/>
			<br/>
			Latest release: 4.0.3 (As of April 15, 2021)
			<br/>
		</div>	
	);
}

function displayReact()
{
	return (
		<div>
			<h1>React Section </h1>
			<a href="https://reactjs.org">React Main Site (https://reactjs.org)</a>
			<br/>
			<a href="https://create-react-app.dev/docs/getting-started/">React Get Started Page</a>
			<br/>
			<a href="https://github.com/facebook/create-react-app#readme"> Create React App Github Home Page</a>
			<br/>
			<a href="https://github.com/facebook/react">React GitHub Home Page</a>
			<br/>
			Latest release: 17.0.2 (As of March 21, 2021)
			<br/>
		</div>
		
	)
}
function ReactVersions() {
  return (
    <div>
		<h1>React Input Samples</h1>
		<hr/>
	  	{displayReact()}
		<hr/>
		{displayRedux()}
		<hr/>
		{displayReactRedux()}
		<hr/>
		{displayReactScripts()}
    </div>
  );
}

export default ReactVersions;
