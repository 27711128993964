import React, {Component} from "react";
import SupplierDetails from "../../../site/supplierdetails/supplierdetails";


class TestSupplierDetails extends Component
{
	constructor(props)
	{
		super(props);
		this.state = 
		{panelToShow:0}
	}
				

renderComponent()
{
	switch (this.state.panelToShow)
	{
		case 1: return <SupplierDetails/>;
		default: return <SupplierDetails/>;
	}
}
render() {
	return (
		<div>
			<button onClick={() => {this.setState({panelToShow:1})}}>Supplier Details</button>&nbsp;
			
			{this.renderComponent()}
		</div>
		);
	}	
}

export default TestSupplierDetails;

