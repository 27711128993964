import React, { Component } from "react";
import { render } from "react-dom";
import { connect, dispatch } from "react-redux";

class LocationSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
        locationFound:false,
        lattitude: 0,
        longitude: 0,
      };
  }

  componentDidMount() {
  }

  renderLocation(displaySeparator, location)
  {
    if (!location || !location.id)
    {
      return '';
    }

    let renderValue = '';
    if (displaySeparator)
    {
      renderValue = renderValue + "->";
    }
    renderValue = <a href="javascript:void(0)" onClick={()=>this.props.selectFunction(location.locationId)}>{renderValue + location.name}</a>;
    console.log("location-summary: renderLocation" + location );
    return renderValue;
  }

  render() {
    console.log("location-summary: selectedLocations=" + this.props.selectedLocations.selectedLocationGroup1.name);
    return (
      <div>Current Location:
        {this.renderLocation(false, this.props.selectedLocations.selectedLocationGroup1)}
        {this.renderLocation(true, this.props.selectedLocations.selectedLocationGroup2)}
        {this.renderLocation(true, this.props.selectedLocations.selectedLocationGroup3)}
        {this.renderLocation(true, this.props.selectedLocations.selectedLocationGroup4)}
        {/* <a href="javascript:void(0)">Texas</a>->
        <a href="javascript:void(0)">Allen</a>-->
        <a href="javascript:void(0)">Allen Chamber of Commerce</a> */}

      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('Location-mapStateToProps 1:', state);
  return { 
    location: state.locationReducer  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSummary);
