
let responseData = {
    data: [{
        vendorid: 0,
        companyName: "Vendor ABC",
        longDescription: "<br/><h1>Vendor ABC</h1><br/><hr/>"
      },{
        vendorid: 0,
        companyName: "Vendor DEF",
        longDescription: "<br/><h1>Vendor DEF</h1><br/><hr/>"
      },{
        vendorid: 0,
        compnyName: "Vendor DEF",
        longDescription: "<br/><h1>Vendor DEF</h1><br/><hr/>"
      },{
        vendorid: 0,
        companyName: "Vendor DEF",
        longDescription: "<br/><h1>Vendor DEF</h1><br/><hr/>"
      },{
        vendorid: 0,
        companyName: "Vendor DEF",
        longDescription: "<br/><h1>Vendor DEF</h1><br/><hr/>"
      },{
        vendorid: 0,
        companyName: "Vendor DEF",
        longDescription: "<br/><h1>Vendor DEF</h1><br/><hr/>"
      },{
        vendorid: 0,
        companyName: "Vendor DEF",
        longDescription: "<br/><h1>Vendor DEF</h1><br/><hr/>"
      },{
        vendorid: 0,
        companyName: "Vendor DEF",
        longDescription: "<br/><h1>Vendor DEF</h1><br/><hr/>"
      }
      ]

}

export function mockGet(value, successFunction, errorFunction){
    successFunction(responseData);
}
