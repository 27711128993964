import React from 'react';
import Game from './game';
//import './index.css';

class TicTacToe extends React.Component {
  render() {
    return (
      <div>
         
            <hr></hr>
            <Game></Game>
      </div>
    );
  }
} 
    
export default TicTacToe; 
  