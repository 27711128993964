import React, { useState } from "react";

function displayIntro()
{
		return (
			<div>
				<h1>Html References</h1>
				<a href="https://www.w3schools.com/html/default.asp">W3 School HTML Reference</a>
				<br/>
			</div>	
		);
}

function displayES6Map()
{
	
}

function HtmlIntro() {
  return (
    <div>
	  	{displayIntro()}
    </div>
  );
}

export default HtmlIntro;
