import { APIRESULTS, CHANGE_VIEW, GET_VENDOR_DETAILS_AND_DISPLAY, GLOBAL_LOCATION_INIT, GLOBAL_COLORSCHEME_INIT } from "../constants/action-types";
import {VIEW_SEARCH, VIEW_VENDOR_DETAILS, VIEW_LOCATION_SELECTION} from '../constants/global';
import {copyLocation} from "../reducer-helper/location-helper";

const initialState = {
  vendorid: 0,
  locationPathId: "",
  location: "",
  parentLocations: [],
  colorScheme: "blue",
  view: VIEW_LOCATION_SELECTION
};

function globalReducer(state = initialState, action) {
  console.log('LOG-GlobalReducer', action);

  let view = state.view;
  let locationCopy = state.location ? ({id: state.location.id, locationPath: state.location.locationPath, name: state.location.name}) : "";
  let parentLocations = state.parentLocations;
  let colorScheme = state.colorScheme;

  switch (action.type) {
    case (CHANGE_VIEW):
      view = action.view;
      break;
    case (GET_VENDOR_DETAILS_AND_DISPLAY):
      view = VIEW_VENDOR_DETAILS;
      break;
	case (GLOBAL_COLORSCHEME_INIT):
	  colorScheme = action.payload.colorScheme;
	  break;
	case (GLOBAL_LOCATION_INIT):
	   locationCopy = copyLocation(action.payload.location);
	   parentLocations = action.payload.parentLocations.map((parentLocation) => copyLocation(parentLocation))
	
  }
console.log("LOG-GlobalReducer: " + colorScheme);

  return  {
    vendorid: 1,
    view: view,
	location: locationCopy,
	colorScheme: colorScheme,
	parentLocations: parentLocations
  };
}



export default globalReducer;