import React, {Component} from "react";
import { connect, dispatch } from "react-redux";
import LocationPanel from '../site/panels/location-panel/location-panel';
import { getLocationGroups, getLocation } from "../js/api-integration/location-api";


class TestLocationCache extends Component
{
	
	constructor(props)
	{
		super(props);
	}
	
	handleChange = (event) => {
		this.props.getLocation(event.target.value);
	}
	
	displayLocationPanel = () => {
		return (
			<div><label>Select a location:</label>
				<select value='2' onChange={this.handleChange}>
					<option value=""></option>
					<option value="US:TEXAS">TEXAS</option>
					<option value="US:TEXAS:ALLEN">ALLEN</option>
					<option value="US:TEXAS:WYLIE">WYLIE</option>

            </select> 
			</div>
		)
	}
		
	render() 
	{
		return(
			<div>
				{this.displayLocationPanel()}
			</div>	
		);
	}
	
}

function mapDispatchToProps(dispatch) {
    return {
        getLocation: (locationId) =>  getLocation(locationId)
    };
  }
    
export default connect(null, mapDispatchToProps)(TestLocationCache);