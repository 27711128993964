import store from "../store";
import { SEARCH_CRITERIA_CATEGORY_ADD, SEARCH_CRITERIA_CATEGORY_DELETE, SEARCH_CRITERIA_CATEGORY_REMOVEALL} from "../constants/action-types";


export function clearAllCategories(){
       store.dispatch({type: SEARCH_CRITERIA_CATEGORY_REMOVEALL, 
				       payload : ''});		
}

export function categoryAddUpdate(categoryId, categoryName, categoryPath, add, appendToCurrentList=true) {	
	console.log('SearchCriteriaAction-categoryAddUpdate: ', categoryId, ':', categoryName, ':', add);
       store.dispatch({type: add ? SEARCH_CRITERIA_CATEGORY_ADD : SEARCH_CRITERIA_CATEGORY_DELETE, 
				       payload : {append: appendToCurrentList, categoryId: categoryId, name: categoryName, categoryPath: categoryPath}});	
}

export function categoryDeleteUpdate(categoryId, categoryName) {
	console.log('SearchCriteriaAction-categoryDeleteUpdate: ', categoryId, ':', categoryName);
       store.dispatch({type: SEARCH_CRITERIA_CATEGORY_DELETE, payload : {append: true, categoryId: categoryId, name: categoryName}});	
}


