import React, { useState } from "react";
import SampleCheckbox from "./sample-checkbox";
import SampleButton from "./sample-button";

function getDisplayComponent(count)
{
	switch (count) {
		case 0:
			return <SampleCheckbox/>
		case 1:
			return <SampleButton/>
	}
}

function ReactInputs() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <div>
		<h1>React Input Samples</h1>
	    <button onClick={() => setCount(0)}>Checkbox Sample</button>
	    <button onClick={() => setCount(1)}>Button Sample</button>


		<hr/>
	  	{getDisplayComponent(count)}
    </div>
  );
}

export default ReactInputs;
