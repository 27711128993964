import React from 'react';
import { connect } from "react-redux";
import {handleMainViewChange} from "../../js/actions/index";
import {VIEW_SEARCH} from '../../js/constants/global';
import  AdvertisementPanel from "../panels/advertisement-panel/advertisement-panel";
import CategoryPanel from "../panels/category-panel/category-panel";
import './supplierdetails.css';

class SupplierDetails extends React.Component {

  buttonPressed = () => {
    this.props.changeview(VIEW_SEARCH);
  }

  renderAdDetails()
{
	return (
		<div>Ad Details
		    <div className="sd-flexResponsiveInnerDiv">Ad  <br/>
 			Ad Description
			</div>
		    <div>Ad  <br/>
 			Ad Description
			</div>
		    <div>Ad  <br/>
 			Ad Description
			</div>
		    <div>Ad  <br/>
 			Ad Description
			</div>
		    <div>Ad  <br/>
 			Ad Description
			</div>
		    <div>Ad  <br/>
 			Ad Description
			</div>
		</div>
	)
}

  render() {
    const vendorDisplayName = this.props.vendor != null ? this.props.vendor.companyName : "";
    const vendorWebsite = this.props.vendor != null ? this.props.vendor.website : "";
    const vendorCustomCode = this.props.vendor != null ? this.props.vendor.longDescription : "";
      return (
              <div>
                <a href="javascript:void(0);" onClick={() => this.props.changeview(VIEW_SEARCH)}>Back</a>
                <div className="sd-flexResponsiveMain">
					<div>{vendorDisplayName}<br/>
                Phone: <br/>
                Website: {vendorWebsite}<br/>
                Address: <br/>
                City, State Zip
					</div>
				
                </div>
                <div className="adPanel">
                {/* <div dangerouslySetInnerHTML={ { __html: listItem.longDescription } }></div> */}
                <CategoryPanel categories={this.props.vendor.categories}/>
				<AdvertisementPanel ads={this.props.vendor.ads} totalads={this.props.vendor.totalAds}/>
                </div>
                <hr/>

            </div>);

    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      changeview: arg => dispatch(handleMainViewChange(arg))
    };
  }
  
  // Maps the reducer state to class props
  const mapStateToProps = state => {
    console.log('SupplierDetails-mapStateToProps 1:', state);
    return { 
      vendor: state.vendorReducer  
    };
  };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(SupplierDetails);
    