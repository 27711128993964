import React, { Component } from "react";

// Props:
//options={[
//                    {id: 1, value: 'option 10', selected:false},
//                    {id: 2, value: 'option 20', selected:true},
//                    {id: 3, value: 'option 30', selected:false},
//                    {id: 4, value: 'option 40', selected:false}
//                ]}

const option1 = [
					{id: 1, value: 'option 110', selected:false},
                    {id: 2, value: 'option 120', selected:true},
                    {id: 3, value: 'option 130', selected:false},
                    {id: 4, value: 'option 140', selected:false}];

		const option2 = [
					{id: 1, value: 'option 210', selected:false},
                    {id: 2, value: 'option 220', selected:true}];

		const option3 = [
					{id: 1, value: 'option 310', selected:false},
                    {id: 2, value: 'option 320', selected:false},
                    {id: 3, value: 'option 330', selected:false},
                    {id: 4, value: 'option 340', selected:false},
                    {id: 5, value: 'option 330', selected:false},
                    {id: 6, value: 'option 340', selected:false}];

class SampleCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
			options: option1,
          };

		// Must bind the optionSelected to 'this'
         this.optionSelectedForEventOnly = this.optionSelectedForEventOnly.bind(this);
         this.optionSelectedWithExtraData = this.optionSelectedWithExtraData.bind(this);
      }

	setCheckboxForId(id, checkValue)
	{
		let options = this.state.options;
		{Array.from(options).map((option) => {
                if (option.id == id )
                {
					option.selected = checkValue
                }
         })}
		this.setState({options:options})
	}

	setAllCheckboxes(options, checkValue)
	{
		{Array.from(options).map((option) => {
			option.selected = checkValue
         })}
		this.setState({options:options})
	}
	
      optionSelectedForEventOnly(event) {
        console.log(event.target);
        alert('optionSelectedForEventOnly: ' + event.target.checked + ' -type: ' + event.type + ' - name: ' + event.target.name + ' - id: ' + event.target.id);
		this.setCheckboxForId(event.target.id, event.target.checked);
    }   

    
      optionSelectedWithExtraData(event, id) {
        console.log(event.target);
        alert('optionSelectedWithExtraData: ' + event.target.checked + ' -type: ' + event.type + ' - name: ' + event.target.name + ' - id: ' + id);
		this.setCheckboxForId(event.target.id, event.target.checked);
      }   
    
      createCheckbox()
      {
          return(
            <div>
            {Array.from(this.state.options).map((option) => {
                if (option.selected)
                {
                    return <div><label>
                    <input
                    name={option.id}
					id={option.id}
                    type="checkbox"
                    checked={true}
					onChange={this.optionSelectedForEventOnly}/>
                    {option.value}</label></div>
        
                } else
                {
                    return <div><label>
                    <input
                    name={option.id}
					id={option.id}
                    type="checkbox"
                    checked={false}
					onChange={e => {this.optionSelectedWithExtraData(e, option.id)}}/>
                    {option.value}</label></div>
                    }
         })}
            </div>
            );
      }    

      render() {
        return (
            <div>
				<h1>Checkbox Sample</h1>
				<button onClick={() => {this.setState({options:option1})}}>Load Option 1</button>
				<button onClick={() => {this.setState({options:option2})}}>Load Option 2</button>
				<button onClick={() => {this.setState({options:option3})}}>Load Option 3</button>
				<br/>
				<button onClick={() => {this.setAllCheckboxes(this.state.options, true)}}>Check All</button>
				<button onClick={() => {this.setAllCheckboxes(this.state.options, false)}}>Clear All</button>

                {this.createCheckbox()}<br/>
            </div>
        );
      }
    }
    
export default SampleCheckbox;
