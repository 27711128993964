import React from 'react';
import { connect, dispatch } from "react-redux";
import BaseSite from './site/basesite';
import TestSite from './testsite/testsite';
import './css/reset.css';
import './css/style.css';

  class Base extends React.Component {
    constructor() {
        super();
        this.state = {
          count: 0,
        };
      }

      componentDidMount()
    {
    }

    mySwitchFunction = (param) => {
	switch(param){
		case 0:
			return <BaseSite/>
		case 1:
	    	return <TestSite/>        
		}
     }

    render() {
      return (
        <div> 
			<button onClick={()=> {this.setState({count:0})}}> Main Site </button> &nbsp;      
			<button onClick={()=> {this.setState({count:1})}}> Test Site </button>       
            {this.mySwitchFunction(this.state.count)}           
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
    };
  }
    
  export default connect(null, mapDispatchToProps)(Base);
  