import React, { Component } from "react";

class SampleButton extends Component {
    constructor(props) {
        super(props);

		// Must bind the optionSelected to 'this'
         this.buttonClicked = this.buttonClicked.bind(this);
      }
    
      buttonClicked(event) {
        console.log('Button clicked: ' + event.target);
        alert('ButtonClicked: ' + ' -type: ' + event.type + ' - name: ' + event.target.name + " -id:" + event.target.id);
      }   

      render() {
        return (
            <div>
				<h1>Button Sample</h1>
				<button id="buttonId" onClick={e => {this.buttonClicked(e)}}>Button</button>
            </div>
        );
      }
    }
    
export default SampleButton;
