import React, {Component} from 'react';
import { connect} from "react-redux";


class TestSearchCriteria extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	displayCategories()
	{
		if (this.props.searchCriteria.categories)
		{
		return Array.from(this.props.searchCriteria.categories).map((category) => {
                        return (<div>{category.name}</div>)
			})			
		}
	}
	
	render()
	{
		return(
			<div>
			Search Criteria:<br/>
			{this.displayCategories()}
			</div>
		);
	}
	
}

function mapDispatchToProps(dispatch) {
  return {
      getCategories: (parentLocationId) =>  {},
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
	console.log('STATE:', state);
  return { 
    searchCriteria: state.searchCriteriaReducer  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestSearchCriteria);