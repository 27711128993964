import React, { Component} from "react";

class ReactFileTemplateClass extends Component {
    constructor(props) {
        super(props);
		this.state = {
			count: 0
		}

      }   

      render() {	
        return (
            <div>
				<h1>Extending Component Template</h1>	
				<br/><br/>
				<div style={{border: "1px solid black", color:"yellow"}}>
				import React, {"{"} Component {"}"} from "react";
				<br/><br/>
				class ReactFileTemplateClass extends Component {}
				</div>
            </div>
        );
      }
    }
    
export default ReactFileTemplateClass;
