import { APIRESULTS } from "../constants/action-types";

const initialState = {
  vendorid: 0,
  vendorDisplayName: "Vendor ABC",
  customHtml: "<br/><h1>Vendor ABC</h1><br/><hr/>"
  
};

function headerReducer(state = initialState, action) {
  console.log('LOG-BoardReducer', action);
  console.log('LOG-BoardReducer', action.payload);
  
 
  if (action.type === APIRESULTS) {
    return action.payload;
  }
  return state;
}


export default headerReducer;