import { GLOBAL_LOCATION_INIT, GLOBAL_COLORSCHEME_INIT } from "../constants/action-types";
import store from "../store";


export function setColorScheme(newColorScheme)
{
	store.dispatch({type: GLOBAL_COLORSCHEME_INIT, payload : {colorScheme: newColorScheme}})
}

export function setLocation(newLocation, parentLocations)
{
	store.dispatch({type: GLOBAL_LOCATION_INIT, payload : {location: newLocation, parentLocations: parentLocations}})
}