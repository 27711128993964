import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import '../category-view.css';


class CityViewMain extends Component
{
	
	constructor(props)
	{
		super(props);
		this.state = 
			{
				view: 0,
				sectionsToHide: []				
			}
	}

	 updateState = (newView = 0, sectionToHideOrUnhide = 0) => {
		let newSectionsToHide = this.state.sectionsToHide;
		console.log("CityViewMaine: updateState 1- " + newSectionsToHide + ':' + sectionToHideOrUnhide)
		if (newSectionsToHide.includes(sectionToHideOrUnhide))
		{
		console.log("CityViewMaine: updateState 2- remove " + sectionToHideOrUnhide)
		
		newSectionsToHide = newSectionsToHide.filter(function(item) {
    		return item !== sectionToHideOrUnhide
			})
		}
		else newSectionsToHide.push(sectionToHideOrUnhide);
		console.log("CityViewMaine: updateState; New Value" + newSectionsToHide);
		
		this.setState(
			{
				view: newView,
				sectionsToHide: newSectionsToHide
			}
		);
	}
	
	handleChange = (event) => {
		this.updateState(event.target.value);
	}
	
	displayLocationPanel = () => {
		return (
			<div><h1>City View</h1>
			</div>
		)
	}
	
	getCategories(...categoriesToSort)
	{
		//return categoriesToSort.sort(function(a,b) {return (a.name < b.name ? -1 : 1)})
		return categoriesToSort.sort(function(a,b) {return (a.sortOrder - b.sortOrder)})
	}
		
	displayCategories(categories)
	{
		console.log("Categories: " + JSON.stringify(categories));
		let categoriesSorted = this.getCategories(...categories);
		console.log("Categories: " + JSON.stringify(categoriesSorted));
		
		return categoriesSorted.map(category => {return (
			<div>
			{console.log("Category Name: " + category.name)}
				{category.name}
				<button onClick={() => this.updateState(this.state.view, category.sortOrder)}>+/-</button>
				{this.displayChildCategories(category)}				
			</div>
			)
		})
	}

	displayChildCategoriesDetails(...categories)
	{
		let categoriesSorted = this.getCategories(...categories);
		
		return categoriesSorted.map(category => {return (
				<div className="two_column">{category.name} <br/>	</div>		
			)
		})
	}
	
	displayChildCategories(category)
	{
		if (category.childCategories && !this.state.sectionsToHide.includes(category.sortOrder))
		{
			return <div className="row">{this.displayChildCategoriesDetails(...category.childCategories)} </div>;
		}
	}
	displayTopCategories(categoriesToView)
	{
		return this.displayCategories(categoriesToView);
	}
	
	renderViewSelector()
	{
		return (
			<div>
				<button onClick={() => this.updateState(0)}>Directory View</button>
				<button onClick={() => this.updateState(1)}>Category View</button>
				<button onClick={() => this.updateState(2)}>Ad View</button>
		    </div>
		)
	}
	
	renderViewDetails()
	{
		switch (this.state.view){
			case 0: return this.displayTopCategories(testCategoriesWithCompanies);
			case 1: return this.displayTopCategories(testCategoriesWithChildCategories);
			case 2: return this.displayTopCategories(testAds);
		}
	}
	render() 
	{
		return(
			<div>
				{this.displayLocationPanel()}<br/>
				{this.renderViewSelector()}
				<div className="category-view-2-main">
				{this.renderViewDetails()}
				</div>
			</div>	
		);
	}
	
}

function mapDispatchToProps(dispatch) {
    return {
    };
  }

const testCategoriesWithChildCategories = [{"key": 1, "sortOrder":1,"name":"Restaurants", childCategories: [{"sortOrder":2,"name":"Cat 1"},{"sortOrder":3,"name":"Cat 2"},{"sortOrder":4,"name":"Cat 3"}]},
{"key": 2, "sortOrder":2,"name":"Home", childCategories: [{"sortOrder":2,"name":"Cat 2"},{"sortOrder":3,"name":"Cat 3"},{"sortOrder":4,"name":"Cat 4"}, { "sortOrder": 5, "name": "Cat 5"}]},
{"key": 3,"sortOrder":3,"name":"Automotive"},
{"key": 4,"sortOrder":4,"name":"Health & Beauty"},
{"key": 5,"sortOrder":5,"name":"Lawn & Garden"},
{"sortOrder":6,"name":"Retail"},
{"sortOrder":7,"name":"Legal"},
{"sortOrder":8,"name":"Medical"},
{"sortOrder":9,"name":"Optical"},
{"sortOrder":10,"name":"Pets"}];

const testCategoriesWithCompanies = [{"key": 1, "sortOrder":1,"name":"Restaurants", childCategories: [{"sortOrder":2,"name":"McDonalds"},{"sortOrder":3,"name":"Burger King"},{"sortOrder":4,"name":"Jack in the Box"}]},
{"key": 2, "sortOrder":2,"name":"Home", childCategories: [{"sortOrder":2,"name":"McDonalds"},{"sortOrder":3,"name":"Burger King"},{"sortOrder":4,"name":"Jack in the Box"}, { "sortOrder": 5, "name": "Fuzzys"}, { "sortOrder": 5, "name": "Torchys"}]},
{"key": 3,"sortOrder":3,"name":"Automotive"},
{"key": 4,"sortOrder":4,"name":"Health & Beauty"},
{"key": 5,"sortOrder":5,"name":"Lawn & Garden"},{"sortOrder":6,"name":"Retail"},{"sortOrder":7,"name":"Legal"},{"sortOrder":8,"name":"Medical"},{"sortOrder":9,"name":"Optical"},{"sortOrder":10,"name":"Pets"}];

const testAds = [{"key": 1, "sortOrder":1,"name":"Children Eat Free", childCategories: [{"sortOrder":2,"name":"McDonalds"},{"sortOrder":3,"name":"Burger King"},{"sortOrder":4,"name":"Jack in the Box"}]},
{"key": 2, "sortOrder":2,"name":"Food Discounts"},
{"key": 3,"sortOrder":3,"name":"Automotive"},
{"key": 4,"sortOrder":4,"name":"Health & Beauty"},
];


const mapStateToProps = state => {
    return { 
		categories: testCategoriesWithChildCategories,
		categories1:[{name: "Restaurants", childCategories:[{name: "restaurant cat 1"}, {name: "restaurant cat 2"}]},
			{name:"Pets", childCategories:[{name: "pet cat 1"}, {name: "pet cat 2"}]}],
		stores:[{name: "Store 1"}, {name: "Store 2"}]
    };
  };
    
export default connect(mapStateToProps, mapDispatchToProps)(CityViewMain);
