

import { CATEGORY_RETRIEVAL_UPDATE} from "../constants/action-types";

const initialState = {
	categories: [
    {sortOrder: 3, name: "Automotive"},
    {sortOrder: 4, name: "Health & Beauty"},
    {sortOrder: 1, name: "Restaurants"},
    {sortOrder: 2, name: "Home"},
    {sortOrder: 6, name: "Retail"},
    {sortOrder: 5, name: "Lawn & Garden"},
    {sortOrder: 7, name: "Legal"},
    {sortOrder: 8, name: "Medical"},
    {sortOrder: 9, name: "Optical"},
    {sortOrder: 10, name: "Pets"},
  ],
};

const secondLevelState = {
	categories: [
    {sortOrder: 3, name: "Italian"},
    {sortOrder: 4, name: "Mexican"},
    {sortOrder: 1, name: "American"},
    {sortOrder: 1, name: "Barbeque"},
    {sortOrder: 1, name: "American"},
    {sortOrder: 2, name: "Fried Chicken"},
    {sortOrder: 6, name: "Kids Eat Free"},
    {sortOrder: 5, name: "Buy One Get One"},
  ],
};

function categoryReducer(state = initialState, action) {
  console.log('LocationReducer', action);
   
  if (action.type === CATEGORY_RETRIEVAL_UPDATE) {
  }

let newState = {
	categories: sortCategories(state.categories)
}

return newState;
}

function sortCategories(categories){
	return categories.sort((a, b) => a.sortOrder - b.sortOrder);
}

export default categoryReducer;