import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import CategoryMainPanel from '../panels/category-main-panel/category-main-panel';
import CategoryPanel from '../panels/category-panel/category-panel';
import SearchResults from '../searchresults/searchresults';

class MainLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      };
  }

  render() {
    return (
      <div>
          <h1>{this.props.globalReducer.view} Page</h1>
          <br/>
          <SearchResults/>
          <CategoryMainPanel/>
          <CategoryPanel/>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  return { 
    globalReducer: state.globalReducer  
  };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(MainLandingPage);
