import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import Categories from '../category-panel/category-list';

class CategoryMainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      };
  }



  render() {
    return (
      <div>

          Top Ads <br/>
          <hr/>
          <Categories/>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  return { 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMainPanel);
