import React from 'react';
import { connect } from "react-redux";
import '../../css/header.css';
import {searchForBannerAds} from "../../js/actions/search-action";
import Location from "../panels/location";

class Header extends React.Component {
    constructor() {
        super();
      }

    render() {
      const vendorDisplayName = this.props.bannerPayload != null ? this.props.bannerPayload.vendorDisplayName : "";
      const vendorCustomCode = this.props.bannerPayload != null ? this.props.bannerPayload.customHtml : "";
      return (
        <div>

        <div className="header_div">
            <div className="header_right">
              <div className="header_location_info">               
                    My Location: City
              </div>
            </div>
        </div>     
            <div className="header_title">
                    Business Finder 
            </div>
        </div>
        // <div className="header_div">
        //     <div>
        //     <Location/> <br/>
        //     This is a header. 
        //     {vendorDisplayName}
        //     <br/>
        //     </div>
        //     <div dangerouslySetInnerHTML={ { __html: vendorCustomCode } }></div>
        // </div>     
      );
    }
  }
  
function mapDispatchToProps(dispatch) {
  return {
    search: arg => dispatch(searchForBannerAds(arg))
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('LOG-Banner.jsx', state);
  return { 
    bannerPayload: state.headerReducer
    
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Header);
  