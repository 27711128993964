import { LOCATION_UPDATE, LOCATION_GET_ADDRESS } from "../constants/action-types";

const initialState = {
	locations:[{id:123, locationId: 'TEST:123', name: 'TEST123'}]
};

function locationCacheReducer(state = initialState, action) {
  console.log('LocationCacheReducer', action);
   
  if (action.type === LOCATION_UPDATE) {
		let locationUpdated = action.payload;
		let updated = false;
		let newLocations = createNewLocationArrayAndUpdate(state.locations, locationUpdated);
		let newState = {locations: newLocations};
		
		if (locationUpdated.parentLocations)
		{
			locationUpdated.parentLocations.forEach(function (parentLocation) 
			{
				let filterLocation = newLocations.filter(function (location) { return parentLocation.id === location.id});
				
				if (filterLocation.length == 0)
				{
					newLocations.push({id: parentLocation.id, locationId: parentLocation.locationId, name: parentLocation.name });
				}
			});
		};

		
      return newState;

    }

return state;
}

function createNewLocationArrayAndUpdate(locations, locationToUpdate) {	
	let updated = false;

	let newLocations =  (locations.map(function (location) {
			if (location.id == locationToUpdate.id)
			{
				updated = true;
				return {id: locationToUpdate.id, locationId: locationToUpdate.locationId, name: locationToUpdate.name };
			}
			else
				return {id: location.id, locationId: location.locationId, name: location.name }
		}));
		
	if (!updated)
		{
			newLocations.push({id: locationToUpdate.id, locationId: locationToUpdate.locationId, name: locationToUpdate.name });
		}
		
	return newLocations;	
		
}

function addUpdateLocation(locations, locationToUpdate) {	
	let updated = false;

	let newLocations =  (locations.map(function (location) {
			if (location.id == locationToUpdate.id)
			{
				return {id: locationToUpdate.id, locationId: locationToUpdate.locationId, name: locationToUpdate.name };
			}
			else
				return {id: location.id, locationId: location.locationId, name: location.name }
		}));
		
	if (!updated)
		{
			newLocations.push({id: locationToUpdate.id, locationId: locationToUpdate.locationId, name: locationToUpdate.name });
		}
		
	return newLocations;	
		
}
export default locationCacheReducer;