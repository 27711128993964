import React, { useState } from "react";

function displayIntro()
{
		return (
			<div>
				<h1>Javascript Array Basics</h1>
				<a href="https://www.w3schools.com/jsref/jsref_obj_array.asp">W3 School Javascript Array Reference</a> <br/>
				<a href="https://medium.com/poka-techblog/simplify-your-javascript-use-map-reduce-and-filter-bd02c593cc2d">Javascript reference for 'Map', 'Reduce', and 'Filter'</a><br/>
				<a href="https://medium.com/poka-techblog/simplify-your-javascript-use-some-and-find-f9fb9826ddfd">Javascript reference for 'Some' and 'Find'</a> <br/>
				<br/>
				<br/>
				Basic Methods: push, pop, shift, unshift <br/>
			<br/> Access arrays via an index:  return myArray[5]
				<br/> <br/>
				<h2>Add to an array</h2>
					<div>
						let myArray = [1, 2, 3]; <br/>
						myArray.push(4)
					</div>
			</div>	
		);
}

function displayES6Map()
{
	return (
		<div>
			<h1>ES6 Map</h1>
			<a href="https://www.w3schools.com/jsref/jsref_obj_array.asp">W3 School Javascript Array Reference</a>
			<br/>
			<br/>
			Example:
			let myArray = [<br/>
				{"{"}id:1 name:"NAME 1" {"}"},<br/>
				{"{"}id:2 name:"NAME 2" {"}"},<br/>
				{"{"}id:3 name:"NAME 3" {"}"},<br/>
			]
			<br/>
			Goal:  Return an array of just the IDs.<br/>
			ForEach solution:<br/><br/>
				var ids = [];<br/>
				myArray.forEach(function (entry) {"{"}<br/>
  					ids.push(entry.id); <br/>
				{"}"});<br/>
			<br/>
			Map Solution:<br/>
			var ids = myArray.map(function (entry) {"{ return entry.id }"});<br/>
			or <br/>
			const ids = myArray.map(entry {"=>"} entry.id);

		</div>
	)
	
}

function displayES6Reduce()
{
	return (
		<div>
			<h1>ES6 Reduce</h1>
			<a href="https://www.w3schools.com/jsref/jsref_obj_array.asp">W3 School Javascript Array Reference</a>
			<br/>
			<br/>
			Example:
			let myArray = [<br/>
				{"{"}age:10 name:"NAME 1" {"}"},<br/>
				{"{"}age:20 name:"NAME 2" {"}"},<br/>
				{"{"}age:30 name:"NAME 3" {"}"},<br/>
			]
			<br/>
			Goal:  Return the total ages<br/>
			<br/>
			Reduce Solution:<br/>
			var totalYears = myArray.reduce(function (accumulator, entry) {"{ return accumulator + entry.age }"}, 0);<br/>

		</div>
	)
	
}

function displayES6Filter()
{
	return (
		<div>
			<h1>ES6 Filter</h1>
			<a href="https://www.w3schools.com/jsref/jsref_obj_array.asp">W3 School Javascript Array Reference</a>
			<br/>
			<br/>
			Example:
			let myArray = [<br/>
				{"{"}age:10 name:"NAME 1" {"}"},<br/>
				{"{"}age:20 name:"NAME 2" {"}"},<br/>
				{"{"}age:30 name:"NAME 3" {"}"},<br/>
			]
			<br/>
			Goal:  Return the only the entries that are 20 and younger<br/>
			<br/>
			Filter Solution:<br/>
			var youngerEntries = myArray.filter(function (entry) {"{ return entry.age <= 20 }"});<br/>
			or <br/>
			var youngerEntries = myArray.filter(entry {" => { entry.age <= 20 }"});<br/>

		</div>
	)
	
}

function JavascriptArrays() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <div>
	  	{displayIntro()}
		<br/>
		{displayES6Map()}
		<hr/>
		{displayES6Reduce()}
		<hr/>
		{displayES6Filter()}
    </div>
  );
}

export default JavascriptArrays;
