import React from 'react';
import { connect } from "react-redux";
import { handleApiResultsSuccess } from "../actions/index";
import store from "../store";
import {get} from './http-calls';

export function getCategories(categoryId, actionType='CATEGORIES_UPDATED') {
    console.log('category-api.getCategories 1:' + categoryId);
    let successFunction = (response) => {
        console.log("GetLocation:" + response.data);
       store.dispatch({type: actionType, payload : response.data});
    }
    let errorFunction = (error) => {
        console.log('Search Error in http call: ' + error);
    }
    get("/categorygroup/" + categoryId + "", successFunction, errorFunction);
}

