import { SEARCH_BANNER } from "../constants/action-types";
import {search} from "../api-integration/api-calls";

export function searchForBannerAds(payload) {
    console.log('search-action: searchForBannerAds 1:');
    search();
    return {type: SEARCH_BANNER, payload: {}};

}

export function searchForBannerAdsApiResponse(payload) {
    console.log('search-action: searchForBannerAds 1:');
    return {type: SEARCH_BANNER, payload: {}};
  }
