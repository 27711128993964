import React, { Component } from "react";
import Button from './button';
import SelectionComponent from './selection-component';

class UIPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectionMode: false,
        selectedLocation: ''
      };
      this.pressButton = this.pressButton.bind(this);
  }

  pressButton() {
      alert('button pressed');
  }

  render() {
    return (
        <div>
        <center>UI Panel</center><br/>
        <hr/>
        Button -> 
        <Button></Button>
        <hr/>
        <SelectionComponent/>
        </div>

    );
  }
}

export default UIPanel;
