

export function copyLocation(location)
{
	if (!location)
	{
		return '';
	}
	return {id: location.id, locationPath: location.locationPath, name: location.name}
}

