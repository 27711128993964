import React, { Component} from "react";
import FileTemplateClass from "./file-template-class";
import FileTemplateFunction from "./file-template-function";

class ReactBasics extends Component {
    constructor(props) {
        super(props);
		this.state = {count: 0}

      }   

	renderComponent() {
		switch(this.state.count)
		{
			case 1: return <FileTemplateClass/>
			case 2: return <FileTemplateFunction/>
			default: return (<div></div>)					
		}
	}

      render() {	
        return (
			<div>
			<button onClick={() => {this.setState({count:1})}}>File Creation: Extending the component</button> &nbsp;
			<button onClick={() => {this.setState({count:2})}}>File Creation: Function example </button>

			{this.renderComponent()}            
            </div>
        );
      }
    }
    
export default ReactBasics;
