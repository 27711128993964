import React, { Component } from "react";
import { render } from "react-dom";
import { connect, dispatch } from "react-redux";
import { getLocationGroup } from "../../../js/api-integration/location-api";
import LocationGroup from "./location-group";
import MyLinkList from '../ui-components/my-link-list-component';

class LocationGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
        locationFound:false,
        lattitude: 0,
        longitude: 0,
      };
  }

  componentDidMount() {
  }

  saveLocation()
  {
    alert("Saving Location");
  }

  getChildLocationName(displaySeparator, locationGroup){
    console.log("======================" + locationGroup);
    return <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(locationGroup.parentLocationId)}>{locationGroup.name}</a>;
  }

  getChildLocations(){
      if (!this.props.location.locationGroups || !this.props.location.locationGroups.locationGroup)
        return <div></div>;
    console.log("==============================================" + this.props.location.locationGroups.locationGroup);
    return <LocationGroup group={this.props.location.locationGroups.locationGroup} selectType='multiselect' selectedLocation={this.props.selectedLocation}/>
  }

  getFirstLevelChildLocations(){
    if (!this.props.location.locationGroups || !this.props.location.locationGroups.locationGroup)
      return <div></div>;
      let locationGroup = this.props.location.locationGroups.firstLocationGroup;
      console.log("==============================================" + locationGroup);
  return <LocationGroup group={locationGroup} selectType='multiselect' selectedLocation={this.props.selectedLocation}/>
}

  getFirstLevelChildLocationsOld(){
      if (!this.props.location.locationGroups && !this.props.location.locationGroups.firstLocationGroup)
        return <div></div>;

    let locationGroup = this.props.location.locationGroups.firstLocationGroup;
    if (locationGroup.numberOfLocationGroups > 1)
    {
        return   <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(locationGroup.parentLocationId)}>{locationGroup.name}</a>;       
    }
      
    let returnCode = this.props.location.locationGroups.firstLocationGroup.childLocations &&  Array.from(this.props.location.locationGroups.firstLocationGroup.childLocations).map((location) => {
        return <LocationGroup group={locationGroup}/>
    });

    // let returnCode = this.props.location.locationGroups.firstLocationGroup.childLocations &&  Array.from(this.props.location.locationGroups.firstLocationGroup.childLocations).map((location) => {
    //   return   <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(location.id)}>{location.name}</a>
    //  });

    console.log(returnCode);
    return returnCode;
  }

  getSecondLevelChildLocations(){
    if (!this.props.location.locationGroups)
    return <div></div>;

    let returnCode = this.props.location.locationGroups.secondLocationGroup.childLocations &&  Array.from(this.props.location.locationGroups.secondLocationGroup.childLocations).map((location) => {
        return <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(location.id)} >{location.name}</a>
    });

    return returnCode;
  }

  render() {
    let currentLocation = this.props.location && this.props.location.locationGroups && this.props.location.locationGroups.locationGroup && 
    this.props.location.locationGroups.locationGroup.parentLocation ? this.props.location.locationGroups.locationGroup.parentLocation.name: '';

    return (
      <div Style="color: purple; border-width: 5px; border-style:solid; border-color: black">
          {/* <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(41)} >Texas</a> */}
          {this.getChildLocations()}
          {/* {this.getFirstLevelChildLocations()} */}
        <br/>
        <button onClick={() => this.saveLocation()}>Apply Changes</button>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
      getLocationGroup: (parentLocationId) =>  getLocationGroup(parentLocationId)
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('Location-mapStateToProps 1:', state);
  return { 
    location: state.locationReducer  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationGroups);
