import React, { useState } from "react";
import "./css-flex.css";

function displayIntro()
{
		return (
			<div>
			<h1>CSS Div</h1>
				<a href="https://www.w3schools.com/css/default.asp">W3 School HTML Reference</a>
				<br/>
				CSS Div: <a href="https://www.w3schools.com/css/css3_flexbox_container.asp">https://www.w3schools.com/css/css3_flexbox_container.asp</a>
				<br/>
			</div>	
		);
}

function displayProperties()
{
		return (
			<div>
				<br/>
				<div style={{border: "2px solid black"}}>
					Div Properties:
					<lu>
						<li>Float Right
							<br/>	<div style={{display:'block', float: "right"}}>Display to the right (right align)</div>
						</li>
					</lu>
						
				</div>
			</div>	
		);
}

function displayExamples()
{
	return (<div style={{background: "green", border:"2px solid black"}}><br/>
	<div style={{background: "green", display:'block', float: "right", border:"2px solid black"}}>Display to the right (right align)</div>
	
	</div>);
	
}

function CssDiv() {
  return (
    <div>
	  	{displayIntro()}<br/>
	  	{displayProperties()}<br/><br/>
		{displayExamples()}
    </div>
  );
}

export default CssDiv;
