import React, { Component } from "react";
import '../../../css/style.css';



// Expected Props passed in:
// selectionList=[{"key": 1, "sortOrder":1,"name":"Restaurants", childCategories: [{"sortOrder":2,"name":"Cat 1"},{"sortOrder":3,"name":"Cat 2"},{"sortOrder":4,"name":"Cat 3"}]},
//{"key": 2, "sortOrder":2,"name":"Home", childCategories: [{"sortOrder":2,"name":"Cat 2"},{"sortOrder":3,"name":"Cat 3"},{"sortOrder":4,"name":"Cat 4"}, { "sortOrder": 5, "name": "Cat 5"}]}];
//
// changeViewAction= function that accepts a 0 (Directory View),1(Category View),2 (Advertisement View) as an argument
// currentView = 0,1,2


class ItemList extends Component
{
	constructor(props)
	{
		super(props);
		this.state={sectionsToHide:[]}
	}


	getCategories(...categoriesToSort)
	{
		//return categoriesToSort.sort(function(a,b) {return (a.name < b.name ? -1 : 1)})
		return categoriesToSort.sort(function(a,b) {return (a.sortOrder - b.sortOrder)})
	}
		
	displayCategories(categories)
	{
		console.log("Categories: " + JSON.stringify(categories));
		let categoriesSorted = this.getCategories(...categories);
		console.log("Categories: " + JSON.stringify(categoriesSorted));
		
		return categoriesSorted.map(category => {return (
			<div>
			{console.log("Category Name: " + category.name)}
				{category.name}
				<button onClick={() => this.updateState(this.state.view, category.sortOrder)}>+/-</button>
				{this.displayChildCategories(category)}				
			</div>
			)
		})
	}

	displayChildCategoriesDetails(...categories)
	{
		let categoriesSorted = this.getCategories(...categories);
		
		return categoriesSorted.map(category => {return (
				<div className="two_column"><a href="#" Style="color:black">{category.name}</a> <br/>	</div>		
			)
		})
	}
	
	displayChildCategories(category)
	{
		if (category.childCategories && !this.state.sectionsToHide.includes(category.sortOrder))
		{
			return <div className="row">{this.displayChildCategoriesDetails(...category.childCategories)} </div>;
		}
	}
	displayTopCategories(categoriesToView)
	{
		return this.displayCategories(categoriesToView);
	}
	
	getButtonStyle(action)
	{
		
		if (this.props.currentView != null && action == this.props.currentView)
		   return "background: blue; color:white";

		return "";			
	}
	
	handleChangeEvent(newView)
	{
		if (this.props.currentView != null && newView != this.props.currentView)
		{
			this.props.changeViewAction(newView);
		}
		
	}
	
	renderViewSelector()
	{
		return (
			<div>
				<button Style={this.getButtonStyle(0)} onClick={() => this.handleChangeEvent(0)}>Directory View</button>
				<button Style={this.getButtonStyle(1)} onClick={() => this.handleChangeEvent(1)}>Category View</button>
				<button Style={this.getButtonStyle(2)} onClick={() => this.handleChangeEvent(2)}>Ad View</button>
		    </div>
		)
	}
	
	renderViewDetails()
	{
		return this.displayTopCategories(this.props.selectionList);
	}
	
	render() 
	{
		return(
			<div>
				{this.renderViewSelector()}
				<div className="category-view-2-main">
				{this.renderViewDetails()}
				</div>
			</div>	
		);
	}
	
}
   
export default ItemList;
