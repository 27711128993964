import React, {Component} from 'react';
import SearchResults from '../site/searchresults/searchresults';

class TestSearchPanel extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	render()
	{
		return(
			<div>
				<SearchResults/>
			</div>
		);
	}
	
}

export default TestSearchPanel;