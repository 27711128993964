import React, { useState } from "react";

function FileTemplateFunction() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <div>
		<h1>Function Template</h1>
		<div style={{border: "1px solid black", color:"yellow"}}>
			import React, {"{"} Component {"}"} from "react";
			<br/><br/>
			function ReactComponentName(){"{"}
			return (enter div components here)
			{"}"}
		</div>

    </div>
  );
}

export default FileTemplateFunction;
