import React, { Component } from "react";

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectionMode: false,
        selectedLocation: ''
      };
      this.pressButton = this.pressButton.bind(this);
  }

  pressButton() {
      alert('button pressed');
  }

  render() {
    return (
        <button onClick={() => this.pressButton()}>BUTTON</button>
    );
  }
}

export default Button;
