import React, { Component} from "react";
import GeoLocation from "../../../site/panels/location-panel/geo-location";

class TestLocationFinder extends Component {
    constructor(props) {
        super(props);
		this.state = {
			count: 0
		}

      }   

      render() {	
        return (
            <div>
				<GeoLocation/>	
            </div>
        );
      }
    }
    
export default TestLocationFinder;