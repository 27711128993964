import { SEARCH_CRITERIA_CATEGORY_ADD, SEARCH_CRITERIA_CATEGORY_DELETE, SEARCH_CRITERIA_CATEGORY_REMOVEALL} from "../constants/action-types";

const initialState = {
	locationId: 0,
    categories:
        [
		]
};

function searchCriteriaReducer(state = initialState, action) {
  console.log('LocationReducer', action);
	let payload = '';
   
   switch (action.type){
	case SEARCH_CRITERIA_CATEGORY_REMOVEALL:
		return initialState;

	case SEARCH_CRITERIA_CATEGORY_ADD:
		 payload = action.payload;
		let currentStateCategories = payload.append ? state.categories : initialState.categories;
		let newAddState = {
			locationId: state.locationId,
			categories: addCategory(currentStateCategories, payload.categoryId, payload.name, payload.categoryPath)
			};
		return newAddState;
		
	case SEARCH_CRITERIA_CATEGORY_DELETE:
		payload = action.payload;
		let newDeleteState = {
			locationId: state.locationId,
			categories: deleteCategory(state.categories, payload.categoryId)
			};
		return newDeleteState;

	default:
		return state;		
	}
}

function addCategory(currentCategories, categoryId, categoryName, categoryPath)
{
	if (currentCategories && currentCategories.length >= 0)
	{
		let result = Array.from(currentCategories).map((category) => {
		   return ({id: category.id, name: category.name})
		});
		result[result.length] = {id:categoryId, name: categoryName, categoryPath: categoryPath};
			
		return result;
			
	}
}

function deleteCategory(currentCategories, categoryId)
{
	console.log('deleteCategory', currentCategories, categoryId)
	if (currentCategories && currentCategories.length > 0)
	{
		
		let result = currentCategories.filter(function( obj ) {
			console.log('deleteCategory:', obj.id, '==', categoryId);
  			return obj.id !== categoryId;
		});
//		let result = Array.from(currentCategories).map((category) => {
//			if (category.id != categoryId)
//		       	{
//				console.log('deleteCategory 1:', category.id);
//				return ({id: category.id, name: category.name})
//				}
//		});
		return result;	
	}
}


export default searchCriteriaReducer;