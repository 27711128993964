import React, { Component } from "react";
import { render } from "react-dom";
import { connect, dispatch } from "react-redux";
import { getLocationGroup } from "../../../js/api-integration/location-api";
import "./locationpanel.css";
class LocationGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
      };
  }

  componentDidMount() {
  }

  openSelectionPanel(){
    this.setState({open: !this.state.open});
  }

  saveLocation(){
    alert("Saving Location");
  }

  getChildLocationName(displaySeparator, locationGroup){
    console.log("======================" + locationGroup);

    if (!locationGroup)
    {
      return <div></div>
    }
    return <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(locationGroup.parentLocationId)}>{locationGroup.name}</a>;       

  }

  getChildLocations(locationGroup){
        return locationGroup.childLocations &&  Array.from(locationGroup.childLocations).map((location) => {
          return <option value={location.name}>{location.name}</option>
        });

          //   return <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(location.id)}>{location.name}</a>
          //  });
  }

  getCheckboxChildLocations(locationGroup){
    return locationGroup.childLocations &&  Array.from(locationGroup.childLocations).map((location) => {
      return <label>
         <input type='checkbox'/>
         {location.name} <br/>
      </label>
    });

}

  getFirstLevelChildLocations(){
      if (!this.props.location.locationGroups && !this.props.location.locationGroups.firstLocationGroup)
        return <div></div>;

    let locationGroup = this.props.location.locationGroups.firstLocationGroup;
    if (locationGroup.numberOfLocationGroups > 1)
    {
        return   <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(locationGroup.parentLocationId)}>{locationGroup.name}</a>;       
    }
      
    let returnCode = this.props.location.locationGroups.firstLocationGroup.childLocations &&  Array.from(this.props.location.locationGroups.firstLocationGroup.childLocations).map((location) => {
     return   <div><label><input type="checkbox" name={location.name}/>{location.name}</label></div>
    });

    // let returnCode = this.props.location.locationGroups.firstLocationGroup.childLocations &&  Array.from(this.props.location.locationGroups.firstLocationGroup.childLocations).map((location) => {
    //   return   <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(location.id)}>{location.name}</a>
    //  });

    console.log(returnCode);
    return returnCode;
  }

  getSecondLevelChildLocations(){
    if (!this.props.location.locationGroups)
    return <div></div>;

    let returnCode = this.props.location.locationGroups.secondLocationGroup.childLocations &&  Array.from(this.props.location.locationGroups.secondLocationGroup.childLocations).map((location) => {
        return <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(location.id)} >{location.name}</a>
    });

    return returnCode;
  }

  renderOpen(){
    return (this.state.open ? '-' : '+');
  }

  openSelectionPanel()
  {
    // alert('openSelected: ' + this.props.selectedLocation);
    // return (this.props.selectedLocation === this.props.group.name);
    return true;
  }

  renderDropDownComponent(){
    return(
      <select>
        {this.getChildLocations(this.props.group)}
  <option value="grapefruit">Grapefruit</option>
  <option value="lime">Lime</option>
  <option selected value="coconut">Coconut</option>
  <option value="mango">Mango</option>
</select>
    )
    // return (this.state.open ? this.getChildLocations(this.props.group) : '')
  }

  renderCheckboxComponent(){
    return(
      this.getCheckboxChildLocations(this.props.group)
    )
    // return (this.state.open ? this.getChildLocations(this.props.group) : '')
  }

  renderComponents(){
    return(
      this.openSelectionPanel() ? this.props.selectType == 'singleselect' ? 
        <div Class="locationSelection">
          {this.renderDropDownComponent()}

        </div>
      :
        <div Class="locationSelection">
          {this.renderCheckboxComponent()}
        </div>
    : ''

    )
  }

  render() {
    let currentLocation = this.props.location && this.props.location.locationGroups && this.props.location.locationGroups.locationGroup && 
    this.props.location.locationGroups.locationGroup.parentLocation ? this.props.location.locationGroups.locationGroup.parentLocation.name: '';

    return (
        <div Class="locationtitle">
        {this.getChildLocationName(false, this.props.group)}
        {/* <button onClick={() => this.openSelectionPanel()}>{this.renderOpen()}</button> */}
        {this.renderComponents()}

        </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
      getLocationGroup: (parentLocationId) =>  getLocationGroup(parentLocationId)
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('Location-mapStateToProps 1:', state);
  return { 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationGroup);
