import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import LocationPanel from "../../../../site/panels/location-panel/location-panel";
import { getLocationGroups, getLocation } from "../../../../js/api-integration/location-api";


class TestLocationPanel extends Component
{
	
	constructor(props)
	{
		super(props);
		this.state = 
			{
				
			}
	}

	updateState = (newSelectedLocation='') => {
		console.log("TestLocationPanel:updateState:" + newSelectedLocation);
		this.setState(
			{
				selectedLocation: newSelectedLocation,
			}
		);
	}
	
	handleChange = (event) => {
		this.updateState(event.target.value);
	}
	
	displayLocationPanel = () => {
		console.log("TestLocationPanel:displayLocationPanel: " + this.state.selectedLocation);
		return (
			<div><label>Select a location:</label>
				<select value='2' onChange={this.handleChange}>
					<option value=""></option>
					<option value="US:TEXAS">TEXAS</option>
					<option value="US:TEXAS:ALLEN">ALLEN</option>

            </select> <br/>
			<hr/><hr/>
				<LocationPanel initialLocation={this.state.selectedLocation}/>
			</div>
		)
	}
		
	render() 
	{
		return(
			<div>
				{this.displayLocationPanel()}
			</div>	
		);
	}
	
}

function mapDispatchToProps(dispatch) {
    return {
        getLocationGroups: (locationId1, locationId2) =>  getLocationGroups(locationId1, locationId2),
        getLocation: (locationId) =>  getLocation(locationId)
    };
  }
    
export default connect(null, mapDispatchToProps)(TestLocationPanel);
