import React, { useState } from "react";
import "./css-flex.css";

function displayIntro()
{
		return (
			<div>
			<h1>CSS Flex</h1>
				<a href="https://www.w3schools.com/css/default.asp">W3 School HTML Reference</a>
				<br/>
				CSS Flex Container: <a href="https://www.w3schools.com/css/css3_flexbox_container.asp">https://www.w3schools.com/css/css3_flexbox_container.asp</a>
				<br/>
				
			</div>	
		);
}

function displayBorderProperties()
{
		return (
			<div>
				<br/>
				<div style={{border: "2px solid black"}}>
					Flex Properties:
					<lu>
						<li>align-content: space-between | space-around | center | stretch | flex-start | flex-end
							<br/>	Modifies the behavior of the flex-wrap property. It is similar to align-items, but instead of aligning flex items, it aligns flex lines
						</li>
						<li>align-items: center | flex-start | flex-end | stretch | baseline	
							<br/>Vertically aligns the flex items when the items do not use all available space on the cross-axis
						</li>
						<li> display: flex
							<br/>Specifies the type of box used for an HTML element
						</li>
						<li> flex-direction: column | column-reverse | row | row-reverse 
							<br/>Specifies the direction of the flexible items inside a flex container
						</li>
						<li> flex-flow:	<br/>A shorthand property for flex-direction and flex-wrap
						</li>
						<li> flex-wrap: wrap | nowrap | wrap-reverse 	
							<br/>Specifies whether the flex items should wrap or not, if there is not enough room for them on one flex line
						</li>
						<li> justify-content: flex-start | flex-end | center | space-around | space-between	
							<br/>Horizontally aligns the flex items when the items do not use all available space on the main-axis
						</li>
					</lu>
						
				</div>
			</div>	
		);
}

function displayExample1()
{
	return (
	   <div className="flexMain">
			<div>Cell 1</div>
			<div>Cell 2</div>
	   </div>	
	);
}

function displayExample2()
{
	return (
	   <div className="flexResponsiveMain">
			<div>Cell 1</div>
			<div>Cell 2</div>
	   </div>	
	);
}

function CssFlex() {
  return (
    <div>
	  	{displayIntro()}<br/>
	  	{displayBorderProperties()}
		{displayExample1()}
		{displayExample2()}
    </div>
  );
}

export default CssFlex;
