import React, { Component } from "react";
import { render } from "react-dom";
import { connect, dispatch } from "react-redux";
import { getLocationGroup, getLocation } from "../../../js/api-integration/location-api";
import { setLocation } from "../../../js/actions/global-action"
import "./locationpanel.css";
class LocationSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
	  newLocationId: '',
	  newLocationName: '',
	  selectedLocation:'',
      };
      this.updateState = this.updateState.bind(this);

  }

  componentDidMount() {
  }

  openSelectionPanel(){
	this.updateState(!this.state.open,
				this.state.newLocationId,
				this.state.newLocationName);
  }

  updateState(showSaveButtons, newLocationIdValue, newLocationNameValue, location)
{
    this.setState({open: showSaveButtons,
					newLocationId: newLocationIdValue,
					newLocationName: newLocationNameValue,
					selectedLocation: location
					});
}

  saveLocation(){
    alert("Saving Location");
  }

  getChildLocationName(displaySeparator, locationGroup){
    console.log("======================" + locationGroup);

    if (!locationGroup)
    {
      return <div></div>
    }
    return <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(locationGroup.parentLocationId)}>{locationGroup.name}</a>;       

  }

  getChildLocations(locationGroup){
        return locationGroup.childLocations &&  Array.from(locationGroup.childLocations).map((location) => {
          return <option value={location.locationId}>{location.name}</option>
        });

          //   return <a href="javascript:void(0)" onClick={() => this.props.getLocationGroup(location.id)}>{location.name}</a>
          //  });
  }

  getCheckboxChildLocations(locationGroup){
    return locationGroup.childLocations &&  Array.from(locationGroup.childLocations).map((location) => {
      return <label>
         <input type='checkbox' onChange={() => this.props.getLocationGroup(location.locationId)}/>
         {location.name} <br/>
      </label>
    });

}
updateRadioButtonLocationChoice(location) 
{
//	console.log('LocationSelection:updateRadioButtonChoice: ' + event.target.id + " - " + event.target.value);
	this.updateState(true, location.id, location.name, location);
}

updateRadioButtonChoice(newLocationId, newLocationName) 
{
//	console.log('LocationSelection:updateRadioButtonChoice: ' + event.target.id + " - " + event.target.value);
	this.updateState(true, newLocationId, newLocationName);
}

createRadioButtons(locationGroup)
  {

   		return locationGroup.childLocations &&  Array.from(locationGroup.childLocations).map((location) => {
      return <label>
         <input id={location.locationId} type='radio' name='group' onChange={() => this.updateRadioButtonLocationChoice(location)}/>
         {location.name} <br/>
      </label>
	});
  }

  renderOpen(){
    return (this.state.open ? '-' : '+');
  }

  openSelectionPanel()
  {
    // alert('openSelected: ' + this.props.selectedLocation);
    // return (this.props.selectedLocation === this.props.group.name);
    return true;
  }

  handleChange = (event) => {
    // <select onChange={() => this.props.getLocationGroup("US:TEXAS:ALLEN")}>
 //   alert("Selected: " + event.target.value);
    this.props.getLocationGroup(event.target.value);
}

  renderDropDownComponent(){
	if (this.state.newLocationId)
  	   this.updateRadioButtonChoice('', '');
	
    return(
    <select onChange={this.handleChange}>
        <option value=""></option>
        {this.getChildLocations(this.props.locationSelection.locationGroup)}
    </select>
    )
  }

  handleApply()
{
	let parentLocations = [];
	if (this.props.locationSelection.selectedItems.selectedItem1)
		parentLocations.push(this.props.locationSelection.selectedItems.selectedItem1);
	if (this.props.locationSelection.selectedItems.selectedItem2)
		parentLocations.push(this.props.locationSelection.selectedItems.selectedItem2);
	if (this.props.locationSelection.selectedItems.selectedItem3)
		parentLocations.push(this.props.locationSelection.selectedItems.selectedItem3);
	if (this.props.locationSelection.selectedItems.selectedItem4)
		parentLocations.push(this.props.locationSelection.selectedItems.selectedItem4);
	
	this.props.applyLocation(this.state.selectedLocation, parentLocations)
}

  renderCheckboxComponent(){
	if (!this.state.newLocationId)
	this.updateRadioButtonChoice(this.props.locationSelection.locationGroup.parentLocation.locationId, this.props.locationSelection.locationGroup.parentLocation.name);

    return(
	<div>
	<input id={this.props.locationSelection.locationGroup.parentLocation.locationId} type='radio' name='group' 
		onChange={() => this.updateRadioButtonChoice(this.props.locationSelection.locationGroup.parentLocation.locationId, this.props.locationSelection.locationGroup.parentLocation.name)} 
		defaultChecked='true'/>All {this.props.locationSelection.locationGroup.parentLocation.name} locations<br/>
	{this.createRadioButtons(this.props.locationSelection.locationGroup)}
	<br/>
	<button onClick={() => this.handleApply()}>Apply</button>
	</div>
//      this.getCheckboxChildLocations(this.props.locationSelection.locationGroup)
    );
  }

  renderComponents(){
    if (!this.props.locationSelection.locationGroup)
       return '';

       return(
       
      this.props.locationSelection.selectionType == 'singleselect' ? 
        <div Class="locationSelection">
          {this.renderDropDownComponent()}

        </div>
      :
        <div Class="locationSelection">
          {this.renderCheckboxComponent()}
        </div>
    )
  }

  renderLocation(displaySeparator, location)
  {
    if (!location || !location.id)
    {
      return '';
    }

    let renderValue = '';
    if (displaySeparator)
    {
      renderValue = renderValue + "->";
    }
    renderValue = <a href="javascript:void(0)" onClick={()=>this.props.getLocationGroup(location.locationId)}>{renderValue + location.name}</a>;
    console.log("location-summary: renderLocation" + location );
    return renderValue;
  }

  render() {
    return (
        <div Class="locationtitle">
        1{this.renderLocation(false, this.props.locationSelection.selectedItems.selectedItem1)}
        2{this.renderLocation(true, this.props.locationSelection.selectedItems.selectedItem2)}
        3{this.renderLocation(true, this.props.locationSelection.selectedItems.selectedItem3)}
        4{this.renderLocation(true, this.props.locationSelection.selectedItems.selectedItem4)}

        {this.renderComponents()
		}

		<br/>
		<br/>
		<br/>
		Selected Location: {this.state.newLocationId} ({this.state.newLocationName})
		
        </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
      getLocationGroup: (parentLocationId) =>  getLocationGroup(parentLocationId),
      getLocation: (locationId) =>  getLocation(locationId),
	  applyLocation: (location, parentLocations) => setLocation(location, parentLocations)
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('Location-Selection-mapStateToProps 1:', state);
  return { 
    locationSelection: state.locationSelectionReducer  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelection);
