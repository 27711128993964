import React, { useState } from "react";
import JavascriptArrays from "./arrays";

function getDisplayComponent(count)
{
	switch (count) {
		case 0:
			return <JavascriptArrays/>
		default:
			return <JavascriptArrays/>
	}
}

function JavascriptCollections() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <div>
		<h1>React Input Samples</h1>
	    <button onClick={() => setCount(0)}>Arrays</button>

		<hr/>
	  	{getDisplayComponent(count)}
    </div>
  );
}

export default JavascriptCollections;
