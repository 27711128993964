import React from 'react';
import { connect } from "react-redux";


class BusinessDetails extends React.Component {

  render() {
      return (
        <div>
            Business Details
        </div>
      );
    }
  } 
    
  function mapDispatchToProps(dispatch) {
    return {
    };
  }
  
  // Maps the reducer state to class props
  const mapStateToProps = state => {
  };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetails);
    