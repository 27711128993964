import { LOCATION_SEARCH, LOCATION_GET_ADDRESS } from "../constants/action-types";

const initialState = {
  locationFound: false,
  selectedLocations:{
      selectedLocationGroup1: {"id":41, "locationId": "US:TEXAS", "name": "Texas"},
      selectedLocationGroup2: {"id":100, "locationId": "US:TEXAS:ALLEN", "name": "Allen"},
      selectedLocationGroup3: '',
      selectedLocationGroup4: '',
  },
  geoLocation:{
    address: '',
    city: '',
    state: '',
    zip: '',
    lattitude: 0,
    longitude: 0
  },
  locationGroups:{
    numberOfLocationGroups:0,
    locationGroup: '',
    firstLocationGroup: '',
    secondLocationGroup: '',
    thirdLocationGroup: '',
    fourthLocationGroup: ''
    }
};

function locationReducer(state = initialState, action) {
  console.log('LocationReducer', action);
   
  if (action.type === LOCATION_SEARCH) {
      return {
          locationFound: true,
          selectedLocations:{
            selectedLocationGroup1: createLocation(state.selectedLocations.selectedLocationGroup1),
            selectedLocationGroup2: createLocation(state.selectedLocations.selectedLocationGroup2),
            selectedLocationGroup3: createLocation(state.selectedLocations.selectedLocationGroup3),
            selectedLocationGroup4: createLocation(state.selectedLocations.selectedLocationGroup4),
        },
         geoLocation:{ 
          latitude: action.payload.latitude,
          longitude: action.payload.longitude
          },
          locationGroups:{
            numberOfLocationGroups:0,
            locationGroup: '',
            firstLocationGroup: '',
            secondLocationGroup: '',
            thirdLocationGroup: '',
            fourthLocationGroup: ''
            }
              };
  }

  if (action.type === LOCATION_GET_ADDRESS) {
    return {
        locationFound: true,
        selectedLocations:{
            selectedLocationGroup1: createLocation(state.selectedLocations.selectedLocationGroup1),
            selectedLocationGroup2: createLocation(state.selectedLocations.selectedLocationGroup2),
            selectedLocationGroup3: createLocation(state.selectedLocations.selectedLocationGroup3),
            selectedLocationGroup4: createLocation(state.selectedLocations.selectedLocationGroup4),
        },
          geoLocation: {
            address: action.payload.address,
            city: action.payload.city,
            state: action.payload.state,
            zip: action.payload.zip,
            latitude: action.payload.latitude,
            longitude: action.payload.longitude    
        },
        locationGroups:{
            numberOfLocationGroups:0,
            locationGroup: '',
            firstLocationGroup: '',
            secondLocationGroup: '',
            thirdLocationGroup: '',
            fourthLocationGroup: ''
            }         
    };
}
if (action.type === "LOCATIONGROUP_UPDATE"){
    return {
        locationFound: state.locationFound,
        selectedLocations:{
            selectedLocationGroup1: createLocation(state.selectedLocations.selectedLocationGroup1),
            selectedLocationGroup2: createLocation(state.selectedLocations.selectedLocationGroup2),
            selectedLocationGroup3: createLocation(state.selectedLocations.selectedLocationGroup3),
            selectedLocationGroup4: createLocation(state.selectedLocations.selectedLocationGroup4),
        },
        geoLocation:{ 
            latitude: state.geoLocation.latitude,
            longitude: state.geoLocation.longitude
            },
        locationGroups: {
            numberOfLocationGroups:getLocationGroupLevel(action.payload),
            locationGroup: getLocationGroup(0, state.locationGroups.locationGroup, action.payload),  
            firstLocationGroup: '',
            secondLocationGroup: '',  
            thirdLocationGroup: '',
            fourthLocationGroup: ''
                }
    };
}
    if (action.type === "LOCATION_SELECTED"){
        return {
            locationFound: true,
            selectedLocations:{
                selectedLocationGroup1: createLocation(state.selectedLocations.selectedLocationGroup1),
                selectedLocationGroup2: createLocation(state.selectedLocations.selectedLocationGroup2),
                selectedLocationGroup3: createLocation(state.selectedLocations.selectedLocationGroup3),
                selectedLocationGroup4: createLocation(state.selectedLocations.selectedLocationGroup4),
            },
            geoLocation:{ 
                latitude: state.geoLocation.latitude,
                longitude: state.geoLocation.longitude
                },
            locationGroups: {
                numberOfLocationGroups:getLocationGroupLevel(action.payload),
                locationGroup: getLocationGroup(0, state.locationGroups.locationGroup, action.payload),  
                firstLocationGroup: getLocationGroup(1, state.locationGroups.firstLocationGroup, action.payload),
                secondLocationGroup: '',  
                thirdLocationGroup: '',
                fourthLocationGroup: ''
                    }
        };
    }
    if (action.type === "LOCATION_UPDATE"){
        return {
            locationFound: true,
            selectedLocations:{
                selectedLocationGroup1: createLocation(action.payload.parentLocations[0]),
                selectedLocationGroup2: createLocation(action.payload.parentLocations[1]),
                selectedLocationGroup3: createLocation(action.payload.parentLocations[2]),
                selectedLocationGroup4: createLocation(action.payload),
            },
            geoLocation:{ 
                latitude: state.geoLocation.latitude,
                longitude: state.geoLocation.longitude
                },
            locationGroups: {
                numberOfLocationGroups:1,
                locationGroup: '',  
                firstLocationGroup: '',
                secondLocationGroup: '',  
                thirdLocationGroup: '',
                fourthLocationGroup: ''
                    }
        };
    }

return state;
}

function getLocationGroupLevel (newLocationGroup)
{
    return 0;
    // return (newLocationGroup.idPath.match(/:/g)||[]).length;
}

function getLocationGroup(numberOfExpectedOccurrences, stateLocationGroup, newLocationGroup) {
        return {
            "id": newLocationGroup.id,
            "name": newLocationGroup.name,
            "parentLocation": createLocation(newLocationGroup.parentLocation),
            "childLocations": createChildLocations(newLocationGroup.childLocations)

        }
}

function getLocationGroupOld(numberOfExpectedOccurrences, stateLocationGroup, newLocationGroup) {
    let locationGroupToUse = newLocationGroup;

    let numberOfOccurrences = (newLocationGroup.idPath.match(/:/g)||[]).length;
    console.log(newLocationGroup.idPath);
    console.log(numberOfOccurrences);


    if (numberOfExpectedOccurrences > numberOfOccurrences){
        locationGroupToUse = stateLocationGroup;
        return '';
    }

    if (numberOfExpectedOccurrences < numberOfOccurrences){
        locationGroupToUse = stateLocationGroup;
    }
        return {
            "id": locationGroupToUse.id,
            "name": locationGroupToUse.name,
            "parentLocation": createLocation(locationGroupToUse.parentLocation),
            "childLocations": createChildLocations(locationGroupToUse.childLocations)

        }
}

function createChildLocations(childLocations) {
    let newChildLocations = [];
    if(childLocations){
        childLocations.forEach(location => {
            newChildLocations.push(createLocation(location));
        })
    } 
    return newChildLocations;

}

function createLocation(location){
    if (!location) {
        return '';
    }

    return {
        "id": location.id,
        "locationId": location.locationId,
        "name": location.name,
    };
}

export default locationReducer;