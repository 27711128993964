import { LOCATION_SELECTION_PANEL_INIT_LOCATIONS} from "../constants/action-types";

const initialState = {
	selectionType: "singleselect",
    selectedItems:{
        selectedItem1: {"id":41, "uniqueId": "US:TEXAS", "name": "Texas"},
        selectedItem2: {"id":100, "uniqueId": "US:TEXAS:ALLEN", "name": "Allen"},
        selectedItem3: '',
        selectedItem4: '',
    },
  
    locationGroup: {},  
};

function locationSelectionReducer(state = initialState, action) {
  console.log('LocationSelectionReducer', action);
   
  if (action.type === LOCATION_SELECTION_PANEL_INIT_LOCATIONS) {
		let selectedItem1 = action.payload.parentLocations[0];
		let selectedItem2 = action.payload.parentLocations[1];
		let selectedItem3 = action.payload.parentLocations[2];
		let selectedItem4 = action.payload.parentLocations[3];
		
		if (!selectedItem1)
			selectedItem1 = action.payload;
		else	
		if (!selectedItem2) 	
			selectedItem2 = action.payload;
		else	
		if (!selectedItem3) 	
			selectedItem3 = action.payload;
		else
		if (!selectedItem4) 	
			selectedItem4 = action.payload;
      return {
		selectionType: "singleselect",	
        selectedItems:{
            selectedItem1: createLocation(selectedItem1),
            selectedItem2: createLocation(selectedItem2),
            selectedItem3: createLocation(selectedItem3),
            selectedItem4: createLocation(selectedItem4),
        },
        };
  }
if (action.type === "LOCATIONGROUP_UPDATE"){
    var selectedItemNumber = getLocationGroupLevel(action.payload) + 1;

	if (selectedItemNumber <= 1)
		selectedItemNumber = getLocationGroupLevelFromPath(action.payload.parentLocation.locationId) + 1;

    console.log("LocationSelectionReducer: " + selectedItemNumber + ":" + action.payload.childLocations.length);

	let selectType = (selectedItemNumber >= 3) ? "multiselect" : "singleselect";
//	let selectType = "singleselect";
	if (!action.payload.childLocations || action.payload.childLocations.length == 0 )
    return {
		selectionType: selectType,	
        selectedItems:{
            selectedItem1: (selectedItemNumber == 1) ? createLocation(action.payload.parentLocation) : createLocation(state.selectedItems.selectedItem1),
            selectedItem2: (selectedItemNumber == 2) ? createLocation(action.payload.parentLocation) : (selectedItemNumber < 2) ? "" : createLocation(state.selectedItems.selectedItem2),
            selectedItem3: (selectedItemNumber == 3) ? createLocation(action.payload.parentLocation) : (selectedItemNumber < 3) ? "" : createLocation(state.selectedItems.selectedItem3),
            selectedItem4: (selectedItemNumber == 4) ? createLocation(action.payload.parentLocation) : (selectedItemNumber < 4) ? "" : createLocation(state.selectedItems.selectedItem4),
        },
        locationGroup: getLocationGroup(0, state.locationGroup, action.payload),  
    };

    return {
		selectionType: selectType,	
        selectedItems:{
            selectedItem1: (selectedItemNumber == 1) ? createLocation(action.payload.parentLocation) : createLocation(state.selectedItems.selectedItem1),
            selectedItem2: (selectedItemNumber == 2) ? createLocation(action.payload.parentLocation) : (selectedItemNumber < 2) ? "" : createLocation(state.selectedItems.selectedItem2),
            selectedItem3: (selectedItemNumber == 3) ? createLocation(action.payload.parentLocation) : (selectedItemNumber < 3) ? "" : createLocation(state.selectedItems.selectedItem3),
            selectedItem4: (selectedItemNumber == 4) ? createLocation(action.payload.parentLocation) : (selectedItemNumber < 4) ? "" : createLocation(state.selectedItems.selectedItem4),
        },
        locationGroup: getLocationGroup(0, state.locationGroup, action.payload),  
    };
}
    if (action.type === "LOCATION_SELECTED"){
        return {
            selectedItems:{
                selectedItem1: createLocation(state.selectedItemss.selectedItem1),
                selectedItem2: createLocation(state.selectedItemss.selectedItem2),
                selectedItem3: '',
                selectedItem4: '',
            },
            locationGroup: getLocationGroup(0, state.locationGroup, action.payload),  
        };
    }

return state;
}

function getLocationGroupLevel (newLocationGroup)
{
    // return 0;
    console.log("LocationSelectionReducer.getLocationGroupLevel: " + newLocationGroup.namePath + "-" + newLocationGroup.idPath.match(/:/g));

    return getLocationGroupLevelFromPath(newLocationGroup.namePath);
}

function getLocationGroupLevelFromPath (namePath)
{
    // return 0;
    console.log("LocationSelectionReducer.getLocationGroupLevel: " + namePath + namePath.match(/:/g));
    return (namePath.match(/:/g)||[]).length;
}

function getLocationGroup(numberOfExpectedOccurrences, stateLocationGroup, newLocationGroup) {
        return {
            "id": newLocationGroup.id,
            "name": newLocationGroup.name,
            "parentLocation": createLocation(newLocationGroup.parentLocation),
            "childLocations": createChildLocations(newLocationGroup.childLocations)
        }
}

function createChildLocations(childLocations) {
    let newChildLocations = [];
    if(childLocations){
        childLocations.forEach(location => {
            newChildLocations.push(createLocation(location));
        })
    } 
    return newChildLocations;

}

function createLocation(location){
    if (!location) {
        return '';
    }

    return {
        "id": location.id,
        "locationId": location.locationId,
        "name": location.name,
    };
}

export default locationSelectionReducer;