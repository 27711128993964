import { GET_VENDOR_DETAILS, GET_VENDOR_DETAILS_AND_DISPLAY } from "../constants/action-types";
import store from "../store";

const getVendor = (dispatch, value) => {
        let searchResponse = {
            type: GET_VENDOR_DETAILS_AND_DISPLAY,
            payload: { 
                address: '1234 first street',
                city: 'Wylie',
                state: 'Tx',
                zip: '75013',
                latitude: 'lat',
                longitude: 'long'
        
    }};
        dispatch(searchResponse);
}

function loadVendorFromSearchListItem(listItem)
{
	let searchResponse = {
            type: GET_VENDOR_DETAILS_AND_DISPLAY,
            payload: { 
				vendorid: listItem.vendorid,
				companyName: listItem.companyName,
				website: listItem.website,
				phone: listItem.phone,
                address: '1234 first street',
                city: 'Wylie',
                state: 'Tx',
                zip: '75013',
                latitude: 'lat',
                longitude: 'long',
				categories: copyCategories(listItem.categories), 
				ads: copyAds(listItem.ads)
        
    }};
	store.dispatch(searchResponse);
}

function copyAds(ads)
{
	let adsList = [];
	if (ads)
	{
		ads.forEach(function (ad){ adsList.push({title: ad.title})});	
	}
	return adsList;
}

function copyCategories(categories)
{
	let categoryList = [];
	if (categories)
	{
		categories.forEach(function (category){ categoryList.push({name: category.name})});	
	}
	return categoryList;
}

export {getVendor, loadVendorFromSearchListItem} 
