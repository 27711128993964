import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import ItemList from "../../panels/ui-components/item-list";
import '../category-view.css';


class ChamberCommerceView extends Component
{
	
	constructor(props)
	{
		super(props);
		this.state = 
			{
				view: 0
				
			}
			
		this.changeViewHandler = this.changeViewHandler.bind(this);
	}

	updateState(newView=0){
		this.setState(
			{
				view: newView,
			}
		);
	}
	
	handleChange = (event) => {
		this.updateState(event.target.value);
	}
	
	displayLocationPanel = () => {
		return (
			<div><h1>Chamber of Commerce View</h1>
			</div>
		)
	}
	
	getCategories(...categoriesToSort)
	{
		//return categoriesToSort.sort(function(a,b) {return (a.name < b.name ? -1 : 1)})
		return categoriesToSort.sort(function(a,b) {return (a.sortOrder - b.sortOrder)})
	}
		
	displayCategories(...categories)
	{
		let categoriesSorted = this.getCategories(...categories);
		
		return categoriesSorted.map(category => {return (
			<div>
			  <a href= "http://espn.com">
				{category.name}
				{this.displayChildCategories(category)}
			  </a>
			</div>
			)
		})
	}
	
	displayChildCategories(category)
	{
		if (category.childCategories)
		{
			return this.displayCategories(...category.childCategories);
		}
	}
	displayTopCategories()
	{
		return this.displayCategories(...this.props.categories);
	}
	
	changeViewHandler(selection)
	{
		this.updateState(selection);		
	}
	
	getDisplayObjects()
	{
		switch (this.state.view){
			case 0: return testCategoriesWithCompanies;
			case 1: return testCategoriesWithChildCategories;
			case 2: return testAds;
		}
	}

	render() 
	{
		return(
			<div>
				{this.displayLocationPanel()}<br/>
				<ItemList selectionList={this.getDisplayObjects()} currentView={this.state.view} changeViewAction={this.changeViewHandler}/>
				<div className="category-view-main">
				{/*this.displayTopCategories()*/}
				</div>
			</div>	
		);
	}	
}



function mapDispatchToProps(dispatch) {
    return {
    };
  }

const testCategories = [{"sortOrder":1,"name":"Restaurants-123", childCategories: [{"sortOrder":2,"name":"McDonalds"},{"sortOrder":3,"name":"Burger King"},{"sortOrder":4,"name":"Jack in the Box"}]},
{"sortOrder":2,"name":"Home", childCategories: [{"sortOrder":2,"name":"McDonalds"},{"sortOrder":3,"name":"Burger King"},{"sortOrder":4,"name":"Jack in the Box"}, { "sortOrder": 5, "name": "Fuzzys"}, { "sortOrder": 5, "name": "Torchys"}]},
{"sortOrder":3,"name":"Automotive"},
{"sortOrder":4,"name":"Health & Beauty"},
{"sortOrder":5,"name":"Lawn & Garden"},
{"sortOrder":6,"name":"Retail"},
{"sortOrder":7,"name":"Legal"},
{"sortOrder":8,"name":"Medical"},
{"sortOrder":9,"name":"Optical"},
{"sortOrder":10,"name":"Pets"}];

const testCategoriesWithChildCategories = [{"key": 1, "sortOrder":1,"name":"Restaurants", childCategories: [{"sortOrder":2,"name":"Cat 1"},{"sortOrder":3,"name":"Cat 2"},{"sortOrder":4,"name":"Cat 3"}]},
{"key": 2, "sortOrder":2,"name":"Home", childCategories: [{"sortOrder":2,"name":"Cat 2"},{"sortOrder":3,"name":"Cat 3"},{"sortOrder":4,"name":"Cat 4"}, { "sortOrder": 5, "name": "Cat 5"}]},
{"key": 3,"sortOrder":3,"name":"Automotive"},
{"key": 4,"sortOrder":4,"name":"Health & Beauty"},
{"key": 5,"sortOrder":5,"name":"Lawn & Garden"},
{"sortOrder":6,"name":"Retail"},
{"sortOrder":7,"name":"Legal"},
{"sortOrder":8,"name":"Medical"},
{"sortOrder":9,"name":"Optical"},
{"sortOrder":10,"name":"Pets"}];

const testCategoriesWithCompanies = [{"key": 1, "sortOrder":1,"name":"Restaurants", childCategories: [{"sortOrder":2,"name":"McDonalds"},{"sortOrder":3,"name":"Burger King"},{"sortOrder":4,"name":"Jack in the Box"}]},
{"key": 2, "sortOrder":2,"name":"Home", childCategories: [{"sortOrder":2,"name":"McDonalds"},{"sortOrder":3,"name":"Burger King"},{"sortOrder":4,"name":"Jack in the Box"}, { "sortOrder": 5, "name": "Fuzzys"}, { "sortOrder": 5, "name": "Torchys"}]},
{"key": 3,"sortOrder":3,"name":"Automotive"},
{"key": 4,"sortOrder":4,"name":"Health & Beauty"},
{"key": 5,"sortOrder":5,"name":"Lawn & Garden"},{"sortOrder":6,"name":"Retail"},{"sortOrder":7,"name":"Legal"},{"sortOrder":8,"name":"Medical"},{"sortOrder":9,"name":"Optical"},{"sortOrder":10,"name":"Pets"}];

const testAds = [{"key": 1, "sortOrder":1,"name":"Children Eat Free", childCategories: [{"sortOrder":2,"name":"McDonalds"},{"sortOrder":3,"name":"Burger King"},{"sortOrder":4,"name":"Jack in the Box"}]},
{"key": 2, "sortOrder":2,"name":"Food Discounts"},
{"key": 3,"sortOrder":3,"name":"Automotive"},
{"key": 4,"sortOrder":4,"name":"Health & Beauty"},
];


const mapStateToProps = state => {
    return { 
		categories: testCategories,
		categories1:[{name: "Restaurants", childCategories:[{name: "restaurant cat 1"}, {name: "restaurant cat 2"}]},
			{name:"Pets", childCategories:[{name: "pet cat 1"}, {name: "pet cat 2"}]}],
		stores:[{name: "Store 1"}, {name: "Store 2"}]
    };
  };
    
export default connect(mapStateToProps, mapDispatchToProps)(ChamberCommerceView);
