import React, { Component } from "react";
import { render } from "react-dom";
import { connect, dispatch } from "react-redux";
import { getCurrentLocation } from "../../js/actions/location-action";
import { getLocationGroup } from "../../js/api-integration/location-api";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
        locationFound:false,
        lattitude: 0,
        longitude: 0,
      };
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(function(position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      console.log(position);
    },
    function(error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  getCurrentPosition(){
    navigator.geolocation.getCurrentPosition(function(position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        console.log(position);
        let newState ={
            locationFound: true,
            lattitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
        // this.setState(newState);
      },
      function(error) {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );  
  }

  render() {
    let address = this.props.location && this.props.location.address ? this.props.location.address : '';
    let latitude = this.props.location && this.props.location.latitude ? this.props.location.latitude : '';
    let longitude = this.props.location && this.props.location.longitude ? this.props.location.longitude : '';
    let city = this.props.location && this.props.location.city ? this.props.location.city : '';
    let state = this.props.location && this.props.location.state ? this.props.location.state : '';
    let currentLocation = this.props.location && this.props.location.locationGroup && 
    this.props.location.locationGroup.parentLocation ? this.props.location.locationGroup.parentLocation.name: '';

    return (
      <div>
        {address} <br/>
        {city}, {state}<br/>
        {latitude}<br/>
        {longitude} <br/>
        <button onClick={this.props.getCurrentLocation}>Get Location</button>
        <button onClick={this.props.getLocationGroup}>Get Location Group</button>
        <br />
        <br />
        {currentLocation}

      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
      getCurrentLocation: () => getCurrentLocation(dispatch),
      getLocationGroup: () =>  getLocationGroup()
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('Location-mapStateToProps 1:', state);
  return { 
    location: state.locationReducer  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
