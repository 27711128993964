import React from 'react';
import { connect } from "react-redux";
import './advertisement.css';
import '../../../js/actions/vendor-action';
import { getVendor } from '../../../js/actions/vendor-action';

class AdvertisementPanel extends React.Component {
    constructor() {
        super();
    }

    selectIndex(value) {
        // this.props.changeview(value);
        alert(value);
      }

    selectAdView(){
        return (<div>single add</div>);
    }
 
    getAds(){
        return this.props.ads &&  Array.from(this.props.ads) .map((ad) => {
         return   <div><a href="javascript:void(0)" onClick={() => this.selectIndex('RESTAURANT_VIEW')}>{ad.title}</a></div>
        });
      }
    
    render() {
//            <center>Sales Ads ({this.props.totalads}) - <a href="javascript:void(0)" onClick={() => this.props.getVendorDetails(1)}>See All...</a></center><br/>
        return (
            <div className="flexResponsiveAd">
            {this.getAds()}
            </div>  
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
      getVendorDetails: arg => getVendor(dispatch, arg)
    };
  }

  
  // Maps the reducer state to class props
  const mapStateToProps = state => {
    return { 
      searchResultsPayload: state.searchResultsReducer,
      globalReducerPayload: state.globalReducer  
    };
  };
    
  export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementPanel);
