import React, { useState } from "react";
import { connect, dispatch } from "react-redux";

class ReducerDump extends React.Component {
    constructor() {
        super();
        this.state = {
          count: 0,
        };
      }

render()
{
  return (
    <div>
		Global: <br/> {JSON.stringify(this.props.global)}<br/><br/>
		Header: <br/> {JSON.stringify(this.props.header)}<br/><br/>
		Location: <br/> {JSON.stringify(this.props.location)}<br/><br/>
		LocationSelection: <br/> {JSON.stringify(this.props.locationSelection)}<br/><br/>
		LocationCacheReducer: <br/> {JSON.stringify(this.props.locationCache)}<br/><br/>
		Category: <br/> {JSON.stringify(this.props.category)}<br/><br/>
		CategorySelection: <br/> {JSON.stringify(this.props.categorySelection)}<br/><br/>
		Vendor: <br/> {JSON.stringify(this.props.vendor)}<br/><br/>
		SearchCriteria: <br/> {JSON.stringify(this.props.searchCriteria)}<br/><br/>
		SearchResults: <br/> {JSON.stringify(this.props.searchResults)}<br/><br/>
		
    </div>
  );
}

}

function mapDispatchToProps(dispatch) {
  return {
   };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  return { 
	global: state.globalReducer,
  	header: state.headerReducer,
  	searchResults: state.searchResultsReducer,
  	location: state.locationReducer,
  	locationSelection: state.locationSelectionReducer,
	locationCache: state.locationCacheReducer,
    categorySelection: state.categorySelectionReducer, 
  	category: state.categoryReducer,
  	vendor: state.vendorReducer,
  	searchCriteria: state.searchCriteriaReducer 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReducerDump);
