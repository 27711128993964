import React, { useState } from "react";
import CssBorder from "./css-border";
import CssFlex from "./css-flex";
import CssDiv from "./css-div";

class CssIntro extends React.Component {
    constructor() {
        super();
        this.state = {
          count: 0,
        };
      }

    componentDidMount()
    {
    }

	updateState = (newCount = 0) => {
		this.setState(
			{
				count: newCount
			}
		);
	}
	
    updateCount(newCount = 0) {
		this.updateState(newCount);
    }


displayIntro()
{
		return (
			<div>
				<h1>CSS References</h1>
				<a href="https://www.w3schools.com/css/default.asp">W3 School HTML Reference</a>
				<br/>
				CSS Properties: <a href="https://www.w3schools.com/cssref/default.asp">https://www.w3schools.com/cssref/default.asp</a>
				<br/>
				
			</div>	
		);
}

displayBorderProperties()
{
		return (
			<div>
				<CssBorder/>
			</div>	
		);
}

	getMainPanel = (param) => {
      switch (this.state.count)
	{
		case 0: return this.displayBorderProperties();
		case 1: return <CssFlex/>;
		case 2: return <CssDiv/>;
		default:
			return <div/>
	
	}
     return <div></div>        
 
     }



    render() {
		console.log('-------------------------------------------');
      return (
        <div ClassName="container">
           <div ClassName="main">
				{this.displayIntro()}
				<br/>
				<button onClick={() => this.updateState(0)}>Border</button>
				<button onClick={() => this.updateState(1)}>Flex</button>
				<button onClick={() => this.updateState(2)}>Div</button>
				<hr/>
	            {this.getMainPanel()}<br/>
            </div>
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
    };
  }
    
  export default CssIntro;
