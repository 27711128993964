import React, {Component} from "react";
import { connect, dispatch } from "react-redux";

import CategoryPanel from '../site/panels/category-panel/category-panel';
import MyCheckbox from '../site/panels/ui-components/my-checkbox';
import {getCategoriesByParent} from '../js/actions/category-selection-action';
import {categoryAddUpdate, clearAllCategories} from '../js/actions/search-criteria-action';


class TestCategoryPanel extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
		}
	}
	
	getCategories(value)
	{
		this.props.getCategoriesByParent(value);
	}

	displaySelectedCategoryId()
	{
		return this.props.categorySelection.parentCategoryId ? this.props.categorySelection.parentCategoryId : '';
	}
	
	displaySelectedCategoryName()
	{
		return this.props.categorySelection.parentCategoryName ? this.props.categorySelection.parentCategoryName : '';
	}
	
	displayChildCategories()
	{
		if (this.props.categorySelection.childCategoryOptions)
		{
		let checkboxOptions = this.props.categorySelection.childCategoryOptions.map((categoryOption) =>
		{
			return ({id: categoryOption.id, value: categoryOption.name, selected: false})
		})
//			return ({id: categoryOption.id, name: categoryOption.name, selected: 'false'}))
			
		return <MyCheckbox options={checkboxOptions}/>
//			return this.props.categorySelection.childCategoryOptions &&  Array.from(this.props.categorySelection.childCategoryOptions).map((categoryOption) => {
//      			return (this.createCheckboxOption(categoryOption))
//    })
			
		}	
	}

    checkboxChangeHandler(e, categoryId, categoryName, categoryPath)
	{
	  this.props.addCategory(categoryId, categoryName, categoryPath, e.target.checked, true);
	
	}
	
	createCheckboxOption(categoryOption)
	{
	   return (<div><label><input id={categoryOption.id} type='checkbox' onChange={e => {this.checkboxChangeHandler(e, categoryOption.id, categoryOption.name, categoryOption.categoryPath, this.checked)}}/>{categoryOption.name}</label></div>);
 	
	}	
	
	displaySelectedCategory()
	{
		return (
			<div>
				Parent Category Id: {this.displaySelectedCategoryId()} <br/>
				Parent Category Name: {this.displaySelectedCategoryName()} <br/>
				Child Categories: {this.displayChildCategories()}
			</div>
		);
	}
	
	handleChange = (event) => {
    // <select onChange={() => this.props.getLocationGroup("US:TEXAS:ALLEN")}>
console.log('HANDLE CHANGE:------------------', event.target.value, "::", event.target.index, "::", event.target);

	let splitId = event.target.value.split(':');
	let id = splitId[0];
	console.log("id: " , id);
	let name= splitId[1];
	console.log("name: ", name);
	let categoryPath = splitId[2];
	console.log("categoryPath: ", categoryPath);
	
    this.props.getCategories(id);
	this.props.addCategory(id, name, categoryPath, true, false);
	}
	
	render()
	{
		return (
			<div>
				<button onClick={() => this.props.clearCategories()}>Clear All Categories</button>
				<br/>
				<br/>
			   <label>Select a category:</label>
				<select value='' index='' onChange={this.handleChange}>
					<option index='0' value="" ></option>
					<option index='1"' value="1:Automotive:AUTOMOTIVE" label="AUTOMOTIVE"></option>
					<option index='2' value="4:Dining:DININGFOOD" label="DINING"></option>
					
	            </select> <br/>
			{this.displaySelectedCategory()} <br/>
			<CategoryPanel />
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
  return {
      getCategories: (parentLocationId) =>  getCategoriesByParent(parentLocationId),
	  addCategory: (categoryId, categoryName, categoryPath, add, append) => categoryAddUpdate(categoryId, categoryName, categoryPath, add, append),
	  clearCategories: () => clearAllCategories()
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  console.log('Location-Selection-mapStateToProps 1:', state);
  return { 
    categorySelection: state.categorySelectionReducer  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCategoryPanel);