import { APIRESULTS } from "../constants/action-types";

const initialState = [{
  vendorid: 0,
  companyName: "Vendor ABC",
  website: "www.test.com",
  phone: "214-555-1234",
  longDescription: "<h2>Local restaurant and bar</h2><br/> Good, wholesome environment, kid and pet friendly",
  categories: [
    {name: "All You Can Eat"},
    {name: "Kids eat free"},
    {name: "Mexican"},
  ],
  ads: [
    {title: "All You Can Eat"},
    {title: "Kids eat free"},
    {title: "Mexican"},  
  ],
  totalAds: 3
},
{
  vendorid: 0,
  companyName: "Vendor DEF",
  longDescription: "<h1>Vendor DEF</h1>"
},
{
  vendorid: 0,
  companyName: "Vendor ABC",
  longDescription: "<h1>Vendor ABC</h1>",
  categories: [
    {name: "All You Can Eat"},
    {name: "Kids eat free"},
    {name: "Mexican"},
  ],
  ads: [
    {title: "All You Can Eat"},
    {title: "Kids eat free"},
    {title: "Mexican"},  
  ],
  totalAds: 5
},{
  vendorid: 0,
  companyName: "Vendor DEF",
  longDescription: "<h1>Vendor DEF</h1>"
}
];

function searchResultsReducer(state = initialState, action) {
  console.log('LOG-SearchResultsReducer', action);
  console.log('LOG-SearchResultsReducer', action.payload);
  
 
  if (action.type === APIRESULTS) {
    return action.payload.data;
  }
  return state;
}


export default searchResultsReducer;