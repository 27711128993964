import { combineReducers } from "redux";
import boardReducer from "./board-reducer";
import gameReducer from "./game-reducer";
import headerReducer from "./header-reducer";
import searchResultsReducer from "./searchresults-reducer";
import globalReducer from "./global-reducer";
import locationReducer from "./location-reducer";
import locationSelectionReducer from "./location-selection-reducer";
import locationCacheReducer from "./location-cache-reducer";
import categorySelectionReducer from "./category-selection-reducer";
import categoryReducer from "./category-reducer";
import vendorReducer from "./vendor-reducer";
import searchCriteriaReducer from "./search-criteria-reducer";

export default combineReducers({
  boardReducer,
  gameReducer,
  globalReducer,
  headerReducer,
  searchResultsReducer,
  locationReducer,
  locationSelectionReducer,
  locationCacheReducer,
  categorySelectionReducer,
  categoryReducer,
  vendorReducer,
  searchCriteriaReducer
})