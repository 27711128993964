import React from 'react';
import { connect } from "react-redux";
import {handleMainViewChange} from "../../js/actions/index";
import SearchResults from '../searchresults/searchresults';
import SupplierDetails from '../supplierdetails/supplierdetails';
import MainLandingPage from './main-landing-page';
import Demo from '../utils/geolocation';
import {VIEW_SEARCH, VIEW_VENDOR_DETAILS} from '../../js/constants/global';
import BreadcrumbPanel from '../panels/breadcrumb-panel/breadcrumb-panel';

class WorkArea extends React.Component {

  buttonPressed_1 = () => {
    // alert('WorkArea - buttonPressed');
    console.log('WorkArea-buttonPressed 1:Pressing a button');
    this.props.changeview('TEST');
  }

  buttonPressed_2 = () => {
    // alert('WorkArea - buttonPressed');
    console.log('WorkArea-buttonPressed 1:Pressing a button');
    this.props.changeview('TEST2');
  }

  mySwitchFunction = (param) => {
    // return <TicTacToe/> 
    console.log('WorkArea.mySwitchFunction:', param); 
    switch (param.view){
        case VIEW_SEARCH:
          return <MainLandingPage/>
        case VIEW_VENDOR_DETAILS:
           return <SupplierDetails></SupplierDetails>
        default:
           return <MainLandingPage/>
    }
   }

  render() {
    const vendorDisplayName = this.props.searchResultsPayload != null ? this.props.searchResultsPayload.companyName : "";
    const vendorCustomCode = this.props.searchResultsPayload != null ? this.props.searchResultsPayload.longDescription : "";
    const searchList = this.props.searchResultsPayload != null ? this.props.searchResultsPayload : {};
      return (
        <div>
            <BreadcrumbPanel/>
            {this.props.globalReducerPayload.view}
            {this.mySwitchFunction(this.props.globalReducerPayload)}
            {/* <button value='SEARCH' style={{width: 50, height: 25 }} onClick={() => this.buttonPressed_1()}>View 1</button> */}
            {/* <button value='SEARCH' style={{width: 50, height: 25 }} onClick={() => this.buttonPressed_2()}>View 2</button> */}

        </div>
      );
    }
  } 
  
  function mapDispatchToProps(dispatch) {
    return {
      changeview: arg => dispatch(handleMainViewChange(arg))
    };
  }

  
  // Maps the reducer state to class props
  const mapStateToProps = state => {
    console.log('WorkArea-mapStateToProps 1:', state);
    return { 
      searchResultsPayload: state.searchResultsReducer,
      globalReducerPayload: state.globalReducer  
    };
  };
    
  export default connect(mapStateToProps, mapDispatchToProps)(WorkArea);