import { CHANGE_VIEW, CHANGE_PLAYER, SELECT_SQUARE, APIRESULTS } from "../constants/action-types";
import store from "../store";

export function selectSquare(payload) {
    console.log('LOG-SelectSquare', payload);
    return { type: SELECT_SQUARE, payload }
  };

export function changePlayer(payload) {
  console.log('LOG-ChangePlayer', payload);
  return { type: CHANGE_PLAYER, payload }
};

export function handleMainViewChange(viewType) {
  console.log('handleMainViewChange ', viewType);
  store.dispatch({type: CHANGE_VIEW, view: viewType});
}

export function handleApiResultsSuccess(payload) {
  let responsePayload =   {responseType: 'SUCCESS', data: payload };
  console.log('ApiResults 1:', payload);
  return {type: APIRESULTS, payload : responsePayload};
};