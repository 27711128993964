import React, { useState } from "react";

function UseStateHook() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <div>
		<h1>UseState Hook</h1>
		<a href='https://reactjs.org/docs/hooks-state.html'> UseState Hook Reference (React.js)</a>
		<br/>
		Easy way to update state for persisting local data.
		<br/>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>

    </div>
  );
}

export default UseStateHook;
