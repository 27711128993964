
import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import '../../css/button.css';

class MyButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      };
  }

  render () {
    return (
    <div className={this.props.classname}>
      {this.props.index}
      {this.props.name}
    </div>);
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  return { 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyButton);

class CategoryButton extends React.Component {
    constructor(props) {
      super(props);
    }  
  }  
  