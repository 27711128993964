import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import MyButton from '../../utils/my-button';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      };
  }

  getCategoryButtons(){
    console.log("getCategoryButtons: " + this.props.categories);
    return this.props.categories &&  Array.from(this.props.categories) .map((category) => {
      return <MyButton index='1' name={category.name} classname='button1'/>
    });
  }


  render() {
    console.log("getCategoryButtons: " + this.props.categories);

    return (
      <div>
          {this.getCategoryButtons()}
          {/* <br/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
          <MyButton index='1' name='A BUTTON' classname='button1'/>
          <MyButton index='2' name='BUTTON WITH LONG NAME' classname='button2'/>
 */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

// Maps the reducer state to class props
const mapStateToProps = state => {
  return { 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
