import React, {Component} from "react";
import TestLocationPanel from '../panels/location-panel/test-location-panel';
import TestLocationCache from '../../testLocationCache';
import TestLocationFinder from './test-location-finder';


class TestLocationPanels extends Component
{
	constructor(props)
	{
		super(props);
		this.state = 
		{panelToShow:0}
	}
				

renderComponent()
{
	switch (this.state.panelToShow)
	{
		case 1: return <TestLocationPanel/>;
		case 2: return <TestLocationCache/>;
		case 3: return <TestLocationFinder/>;
		default: return <div></div>;
	}
}
render() {
	return (
		<div>
			<button onClick={() => {this.setState({panelToShow:1})}}>LocationPanel</button>&nbsp;
			<button onClick={() => {this.setState({panelToShow:2})}}>Location Cache</button>&nbsp;
			<button onClick={() => {this.setState({panelToShow:3})}}>Location Finder (Geo Location)</button>&nbsp;
			
			{this.renderComponent()}
		</div>
		);
	}	
}

export default TestLocationPanels;

