import React from 'react';
import { connect } from "react-redux";
import {handleMainViewChange} from "../../js/actions/index";
import './nav.css';

class Navigation extends React.Component {
    constructor() {
        super();
    }

    myFunction() {
        var x = document.getElementById("myTopnav");
        if (x != null && x.className != null) {
            if (x.className === "topnav") {
                x.className += " responsive";
            } else {
                x.className = "topnav";
            }    
        }
    }


    changeView(value) {
        this.props.changeview(value);
      }
    
    render() {
        return (
            <div>
                <div className="topnav" id="myTopnav">
                    <a href="#home" className="active">Home</a>
                    <a href="javascript:void(0)" onClick={() => this.changeView('RESTAURANT_VIEW')}>Restaurant</a>
                    <a href="javascript:void(0)" onClick={() => this.changeView('SERVICES_VIEW')}>Services</a>
                    <a href="#contact">Contact</a>
                    <a href="#about">About</a>
                    <a href="javascript:void(0);" className="topnav" onClick={this.myFunction()}>
                        <i className="box-shadow-menu"></i>
                    </a>
                </div>
            </div>  
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
      changeview: arg => dispatch(handleMainViewChange(arg))
    };
  }

  
  // Maps the reducer state to class props
  const mapStateToProps = state => {
    return { 
      searchResultsPayload: state.searchResultsReducer,
      globalReducerPayload: state.globalReducer  
    };
  };
    
  export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
